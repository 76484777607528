@let isMobile = isMobile$ | async;

<div
  *ngIf="!isMobile; else modalWindowView"
  class="messagingContainer"
  id="messagingContainer"
  [ngClass]="{ expanded: expanded }"
  data-layout="column"
>
  <div
    class="messagingHeader"
    data-layout="row"
    data-layout-align="space-between center"
    data-layout-gap="16px"
    (click)="expanded = !expanded"
  >
    <div data-layout="row" data-layout-align="start center" data-layout-gap="16px">
      <app-title [type]="'h5'" [title]="'shared.labels.messaging' | language"></app-title>

      <span *ngIf="totalMessagingNotifications" class="messagingQty">{{ totalMessagingNotifications }}</span>
    </div>

    <app-icon [size]="24" [icon]="expanded ? 'arrow_down_filled' : 'arrow_up_filled'" [type]="'outline'"></app-icon>
  </div>

  <ng-container *ngTemplateOutlet="messagingContent"></ng-container>
  <div class="aiButtonContainer">
    <app-ai-widget-button />
  </div>
</div>

<ng-template #modalWindowView>
  <app-default-modal [title]="'shared.labels.messaging' | language" [size]="'small'" [hideSubmitButton]="true">
    <ng-container *ngTemplateOutlet="messagingContent"></ng-container>
  </app-default-modal>
</ng-template>

<ng-template #messagingContent>
  <div *ngIf="expanded || isMobile" [@inOutAnimation] data-layout="column" class="messagesContainer">
    <div *ngIf="loading" class="progressBarContainer">
      <mat-progress-bar mode="query"></mat-progress-bar>
    </div>

    <div
      *ngFor="let message of messages"
      class="messageContainer"
      [ngClass]="{ disabled: disabled$ | async }"
      data-layout="column"
      data-layout-gap="4px"
      (click)="viewComments(message)"
    >
      <div data-layout="row" data-layout-align="space-between center" data-layout-gap="16px">
        <span class="defaultText bold">{{ message.eventName }}</span>
        <span class="caption">{{ message.alertNotification?.createdDatetime | userDate: 'MM/dd/yyyy' }}</span>
      </div>
      <div data-layout="row" data-layout-align="space-between center" data-layout-gap="16px">
        <span class="defaultText small">
          {{ message.alertNotification?.createdBy?.name + ': ' + getTextContentFromHTML(message.alertNotification?.message) }}
        </span>
        <span *ngIf="message.unreadCount" class="messagingQty secondary">{{ message.unreadCount }}</span>
      </div>
    </div>

    <div *ngIf="!messages.length && !loading" class="caption noItems" data-layout="column" data-layout-gap="32px">
      <div>
        <span>{{ 'messaging.messagingNoitems_1' | language }}</span>
        <a (click)="openInBrowser('https://connectsx.atlassian.net/servicedesk/customer/portal/1/article/2295234668')">{{
          'messaging.messagingNoitems_2' | language
        }}</a
        >.
      </div>

      <app-empty-state [resetPadding]="true" [resetHeight]="true"></app-empty-state>
    </div>
  </div>
</ng-template>
