import { Injectable } from '@angular/core';
import { ApiService } from '@shared/classes/api-service';
import { catchError, map, Observable, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpHelperService } from '@services/internal/http-helper.service';
import { IEndgrateSession, IEndgrateTransfer } from '@shared/interfaces';
import { NoteModel, PageableModel } from '@shared/models';
import { EEndgrateIntegrationType, EEngrateScheduleType, EEngrateSessionType } from '@shared/enum';
import { BasePageableParams } from '@shared/models/build-models/pageable/base-pageable-params';

@Injectable({ providedIn: 'root' })
export class EndGrateIntegrationService extends ApiService {
  createEndgrateSession(sessionType: keyof typeof EEngrateSessionType, saveSession: boolean = false): Observable<string> {
    return this.get<string>(
      `endgrate/session/create?sessionType=${sessionType}&saveSession=${saveSession}`,
      HttpHelperService.addResponseTypeText({})
    ).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  createEndgrateTransfer(params: {
    sessionId: string;
    entityType: keyof typeof EEndgrateIntegrationType;
    continuous: boolean;
    scheduleType: keyof typeof EEngrateScheduleType;
  }): Observable<string> {
    return this.post<string>(`endgrate/transfer/create`, params).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  runEndgrateTransfer(transferId: string, fullSync: boolean): Observable<string> {
    return this.get<string>(`endgrate/transfer/${transferId}/run?fullSync=${fullSync}`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getEndgrateSessionsPageable(
    params: BasePageableParams & { isAdditionalConnections: boolean }
  ): Observable<PageableModel<IEndgrateSession>> {
    return this.get<PageableModel<IEndgrateSession>>(`endgrate/session/pageable`, HttpHelperService.addResponseTypeJSON(params)).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getTransfersPageable(params: { sessionId: string } & BasePageableParams): Observable<PageableModel<IEndgrateTransfer>> {
    return this.get<PageableModel<IEndgrateTransfer>>(`endgrate/transfer/pageable`, HttpHelperService.addResponseTypeJSON(params)).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getSessionDetail(sessionId: string): Observable<IEndgrateSession> {
    return this.get<IEndgrateSession>(`endgrate/session/${sessionId}`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  deleteSession(sessionId: string): Observable<boolean> {
    return this.delete<void>(`endgrate/session/${sessionId}`).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.deleted');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getTransferComments(transferId: string): Observable<NoteModel[]> {
    return this.get<NoteModel[]>(`endgrate/transfer/${transferId}/comments`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of([]);
      })
    );
  }

  createTransferComment(transferId: string, sessionId: string, message: string, mentionUserIds: string[] = []): Observable<string> {
    const mentions = mentionUserIds?.length ? `?mentionUserIds=${mentionUserIds}` : '';
    return this.post<string>(`endgrate/transfer/${transferId}/comments?sessionId=${sessionId}` + mentions, message).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  changeTransferComment(
    transferId: string,
    transferCommentId: string,
    message: string,
    existMentionUserIds: string[] = [],
    newMentionUserIds: string[] = []
  ): Observable<string> {
    return this.post<string>(
      `endgrate/transfer/${transferId}/comment/${transferCommentId}?existMentionUserIds=${existMentionUserIds}&newMentionUserIds=` +
        newMentionUserIds,
      message
    ).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  deleteTransferComment(transferId: string, transferCommentId: string): Observable<boolean> {
    return this.delete<void>(`endgrate/transfer/${transferId}/comment/${transferCommentId}`).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.deleted');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }
}
