<div class="full-width" data-layout="column" data-layout-align="start stretch" data-layout-gap="4px">
  <app-input-with-chips-kit
    *ngIf="multiple; else single"
    data-flex="100"
    [dataCy]="'recipeInput'"
    [chipDataCy]="'recipeChip'"
    [chipRemoveData_cy]="'recipeRemoveChipButton'"
    [selectedChips]="selectedItems"
    [label]="label"
    [showAddChipButton]="true"
    [disabled]="disabled"
    [invalidInput]="invalid"
    [forceBlurEvent]="forceBlurEvent"
    [parseChipByProperty]="'name'"
    [selectable]="true"
    [showLinkToDetail]="showLinkButton"
    [detailPageLink]="'/directory/preference-cards/edit/'"
    [debounceEvents]="['keyup', 'focus']"
    [debounceTime]="1000"
    [required]="required ? !selectedItems.length : false"
    [requiredView]="required ? !!selectedItems.length : false"
    [removable]="removable"
    [autocompleteName]="autocomplete"
    (chipsRemovedEmitter)="removeChip($event.id)"
    (debounceTimeEndedEmitter)="searchRecipes($event)"
  ></app-input-with-chips-kit>

  <ng-template #single>
    <app-input-kit
      data-flex="100"
      [required]="required"
      [label]="label"
      [dataCy]="'recipeInput'"
      [initialValue]="recipe"
      [icons]="[
        showClearButton && !disabled ? 'close' : null,
        showLinkButton && recipe?.id ? 'link' : null,
        (isMobile$ | async) === false && showViewInModalButton && recipe?.id ? 'search' : null,
        matchEventData ? 'help' : null
      ]"
      [linkDetailPage]="'/directory/preference-cards/edit/' + recipe?.id"
      [tooltipText]="matchEventData ? 'shared.tooltips.recipesByEventData' : null"
      [disabled]="disabled"
      [forceBlurEvent]="forceBlurEvent"
      [debounceEvents]="['keyup', 'focus']"
      [debounceTime]="1000"
      [autocompleteName]="autocomplete"
      (debounceTimeEndedEmitter)="searchRecipes($event)"
      (iconClickEmitter)="doIconAction($event)"
      (valueChangedEmitter)="valueChangedEmitter.emit($event)"
    ></app-input-kit>
  </ng-template>

  <app-checkbox-kit
    *ngIf="procedure?.id || physicians?.length"
    class="subtitleContainer"
    [disabled]="disabled"
    [title]="'shared.filter.filterByEventData' | language"
    [initialValue]="matchEventData"
    (checkEmitter)="matchEventData = $event"
  ></app-checkbox-kit>

  <mat-autocomplete #autocomplete="matAutocomplete" [displayWith]="displayFn">
    <mat-option *ngIf="(loading$ | async) === true || (initialLoading$ | async) === true" class="is-loading" disabled>
      <mat-progress-spinner value="30" class="spinner" mode="indeterminate" diameter="30"></mat-progress-spinner>
    </mat-option>
    <ng-container *ngIf="(loading$ | async) === false && (initialLoading$ | async) === false">
      <mat-option data-cy="recipeOption" *ngIf="!searchedData.length; else options" disabled class="connectSxAutocompleteOption">
        <span>{{ 'shared.labels.noItemsFound' | language }}</span>
      </mat-option>
      <ng-template #options>
        <cdk-virtual-scroll-viewport
          itemSize="0"
          class="virtualAutocompleteScroll"
          [style.height.px]="searchedData.length < 3 ? searchedData.length * 50 : 150"
        >
          <mat-option
            data-cy="recipeOption"
            *cdkVirtualFor="let recipe of searchedData"
            class="connectSxAutocompleteOption"
            [value]="recipe"
            (click)="selectOption(recipe)"
          >
            <span>{{ recipe.name }}</span>
            @if (emphasizedPrefCard?.id === recipe.id) {
              <b
                ><small>{{ 'shared.labels.assignedToEvent' | language }}</small></b
              >
            }
          </mat-option>
        </cdk-virtual-scroll-viewport>
      </ng-template>
    </ng-container>
  </mat-autocomplete>
</div>
