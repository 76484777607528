<app-create-entity-modal
  [title]="'facilities.createFacilityTitle' | language"
  [showCreateButton]="stepperRef?.selectedIndex === (permissionService.isGranted('directory', 'canReadCustomerIdAtFacilityPage') ? 4 : 3)"
  [showPrevStepButton]="
    stepperRef?.selectedIndex > 0 &&
    stepperRef?.selectedIndex <= (permissionService.isGranted('directory', 'canReadCustomerIdAtFacilityPage') ? 4 : 3)
  "
  [showNextStepButton]="stepperRef?.selectedIndex < (permissionService.isGranted('directory', 'canReadCustomerIdAtFacilityPage') ? 4 : 3)"
  [disableNextStepButton]="formName?.invalid || (stepperRef?.selectedIndex === 3 && !checkParLevelValidation())"
  [disableCreateButton]="!formsValid || (loading$ | async) === true"
  [confirmationRequired]="!redirect"
  [showCloseConfirmation]="formName?.touched"
  (prevStepEmitter)="stepperRef.previous()"
  (nextStepEmitter)="stepperRef.next()"
  (createEmitter)="redirect ? submitWithSubscription() : submitWithoutRedirect()"
>
  <mat-stepper #stepper [linear]="true" [disableRipple]="true">
    <ng-template matStepperIcon="edit">
      <mat-icon>check</mat-icon>
    </ng-template>
    <mat-step [label]="'facilities.informationLabel' | language" [stepControl]="formName">
      @if (formName?.get('googlePlaceId')?.value?.length) {
        <div data-layout="column" data-layout-gap="8px">
          <span>
            <span class="emphasizedSuccess">{{ 'shared.labels.googleVerifiedAddress' | language }}</span>
          </span>
          <span></span>
        </div>
      }
      <app-facility-form-name
        [createEntityFlow]="true"
        (formNameValueChangeEmitter)="formName = $event; checkValidation()"
      ></app-facility-form-name>
    </mat-step>
    <mat-step [label]="'shared.labels.contacts' | language" [stepControl]="formPrimaryContacts">
      <app-form-additional-contacts
        [createEntityFlow]="true"
        [showAddNewContactsButton]="!additionalContacts.length"
        [type]="'PRIMARY'"
        [canEdit]="true"
        [showDefaultEmailCheckboxes]="true"
        [unCheckDefaultSalesOrderEmails]="unCheckDefaultSalesOrderEmailsTrigger"
        [unCheckDefaultInvoiceEmails]="unCheckDefaultInvoicesEmailsTrigger"
        (formNameValueChangeEmitter)="formPrimaryContacts = $event; checkValidation()"
        (addNewContactEmitter)="addFacilityContact()"
        (unCheckDefaultSalesOrderEmailsEmitter)="unCheckDefaultSalesOrderEmailsTrigger = unCheckDefaultSalesOrderEmailsTrigger + 1"
        (unCheckDefaultInvoiceEmailsEmitter)="unCheckDefaultInvoicesEmailsTrigger = unCheckDefaultInvoicesEmailsTrigger + 1"
      ></app-form-additional-contacts>
      <app-form-additional-contacts
        *ngFor="let contact of additionalContactsData; let i = index; last as isLast"
        [contact]="contact"
        [canEdit]="true"
        [createEntityFlow]="true"
        [showDefaultEmailCheckboxes]="true"
        [showAddNewContactsButton]="isLast && i < 4"
        [type]="'ADDITIONAL_PRIMARY'"
        [unCheckDefaultSalesOrderEmails]="unCheckDefaultSalesOrderEmailsTrigger"
        [unCheckDefaultInvoiceEmails]="unCheckDefaultInvoicesEmailsTrigger"
        (formNameValueChangeEmitter)="additionalContacts[i] = $event; checkValidation()"
        (addNewContactEmitter)="addFacilityContact()"
        (deleteAdditionalContacts)="deleteAdditionalContacts(i, contact.id)"
        (unCheckDefaultSalesOrderEmailsEmitter)="unCheckDefaultSalesOrderEmailsTrigger = unCheckDefaultSalesOrderEmailsTrigger + 1"
        (unCheckDefaultInvoiceEmailsEmitter)="unCheckDefaultInvoicesEmailsTrigger = unCheckDefaultInvoicesEmailsTrigger + 1"
      ></app-form-additional-contacts>
    </mat-step>
    <mat-step [label]="'facilities.billingInformationLabel' | language" [stepControl]="formBillingContacts">
      @if (formBillingContacts?.get('paymentGooglePlaceId')?.value?.length) {
        <div data-layout="column" data-layout-gap="8px">
          <span>
            <span class="emphasizedSuccess">{{ 'shared.labels.googleVerifiedAddress' | language }}</span>
          </span>
          <span></span>
        </div>
      }
      <app-facility-form-billing-contacts
        [valid]="formsValid"
        [createEntityFlow]="true"
        [unCheckDefaultSalesOrderEmails]="unCheckDefaultSalesOrderEmailsTrigger"
        [unCheckDefaultInvoiceEmails]="unCheckDefaultInvoicesEmailsTrigger"
        (formNameValueChangeEmitter)="formBillingContacts = $event; checkValidation()"
        (unCheckDefaultSalesOrderEmailsEmitter)="unCheckDefaultSalesOrderEmailsTrigger = unCheckDefaultSalesOrderEmailsTrigger + 1"
        (unCheckDefaultInvoiceEmailsEmitter)="unCheckDefaultInvoicesEmailsTrigger = unCheckDefaultInvoicesEmailsTrigger + 1"
      ></app-facility-form-billing-contacts>
    </mat-step>
    <mat-step [label]="'facilities.parLevelLabel' | language">
      <app-facility-par-level
        [isLoading]="loading$ | async"
        [facilityParLevel]="facilityParLevel"
        [disabled]="false"
        [createEntityFlow]="true"
        (deleteParLevelEmitter)="deleteParLevel($event); markAsTouched()"
        (editParLevelEmitter)="editParLevel($event); markAsTouched()"
        (addNewParLevelEmitter)="addNewParLevel(); markAsTouched()"
        (selectCatalogEmitter)="selectCatalog($event); markAsTouched()"
        (selectManufacturerEmitter)="selectManufacturer($event); markAsTouched()"
        (selectLocationEmitter)="selectLocation($event); markAsTouched()"
      ></app-facility-par-level>
    </mat-step>
    <mat-step
      [label]="'facilities.integrationsLabel' | language"
      [stepControl]="formIntegration"
      *appHasPermission="{ scope: 'directory', permissionName: 'canReadCustomerIdAtFacilityPage' }"
    >
      <app-integration-customer-ids
        [createEntityFlow]="true"
        (formNameValueChangeEmitter)="formIntegration = $event; checkValidation()"
      ></app-integration-customer-ids>
    </mat-step>
  </mat-stepper>
</app-create-entity-modal>
