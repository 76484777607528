import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BackPrevPageComponent } from '@shared/components/back-prev-page/back-prev-page.component';
import { ConfirmComponent } from './components/modals/confirm/confirm.component';
import { MatSortModule } from '@angular/material/sort';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatStepperModule } from '@angular/material/stepper';
import { MobileMenuComponent } from './components/global/navigation/mobile/mobile-menu/mobile-menu.component';
import { RouterModule } from '@angular/router';
import { AttachmentsComponent } from '@shared/components/upload/attachments/attachments.component';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { AssignTransferInventoryToContainerComponent } from '@shared/components/form-elements/autocomplete/assign-to-container/assign-transferred-inventory-to-container.component';
import { InventoryOptionShortModelComponent } from './components/form-elements/inventory-option-short-model/inventory-option-short-model.component';
import { InventorySelectedShortModelWithCountComponent } from './components/inventory-selected-short-model-with-count/inventory-selected-short-model-with-count.component';
import { MobileScreenTitleComponent } from './components/global/navigation/mobile/mobile-screen-title/mobile-screen-title.component';
import { InfoBlockComponent } from './components/info-block/info-block.component';
import { LocationsAutocompleteComponent } from './components/form-elements/autocomplete/locations-autocomplete/locations-autocomplete.component';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { CatalogAutocompleteComponent } from './components/form-elements/autocomplete/catalog-autocomplete/catalog-autocomplete.component';
import { FacilityAutocompleteComponent } from './components/form-elements/autocomplete/facility-autocomplete/facility-autocomplete.component';
import { PhysicianAutocompleteComponent } from './components/form-elements/autocomplete/physician-autocomplete/physician-autocomplete.component';
import { ProcedureAutocompleteComponent } from './components/form-elements/autocomplete/procedure-autocomplete/procedure-autocomplete.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { GlobalLoaderComponent } from '@shared/components/global/global-loader/global-loader.component';
import { ManufacturerAutocompleteComponent } from './components/form-elements/autocomplete/manufacturer-autocomplete/manufacturer-autocomplete.component';
import { ProductAutocompleteComponent } from './components/form-elements/autocomplete/product-autocomplete/product-autocomplete.component';
import { ContainerAutocompleteComponent } from './components/form-elements/autocomplete/container-autocomplete/container-autocomplete.component';
import { UserAutocompleteComponent } from './components/form-elements/autocomplete/user-autocomplete/user-autocomplete.component';
import { OrganizationSwitcherComponent } from './components/form-elements/organization-switcher/organization-switcher.component';
import { SelectItemsCountComponent } from './components/form-elements/select-items-count/select-items-count.component';
import { TabUIComponent } from './components/tab-ui/tab-ui.component';
import { ButtonKitComponent } from './components/form-elements/ui-kit/button/button-kit.component';
import { InputKitComponent } from './components/form-elements/ui-kit/input/input-kit.component';
import { CheckboxKitComponent } from './components/form-elements/ui-kit/checkbox/checkbox-kit.component';
import { RadioKitComponent } from './components/form-elements/ui-kit/radio/radio-kit.component';
import { SelectKitComponent } from './components/form-elements/ui-kit/select/select-kit.component';
import { InputWithSelectKitComponent } from './components/form-elements/ui-kit/input-with-select/input-with-select-kit.component';
import { DatepickerKitComponent } from './components/form-elements/ui-kit/datepicker/datepicker-kit.component';
import { TooltipKitComponent } from './components/form-elements/ui-kit/tooltip-kit/tooltip-kit.component';
import { ModalTooltipComponent } from './components/form-elements/ui-kit/tooltip-kit/modal-tooltip/modal-tooltip.component';
import { IconComponent } from './components/form-elements/ui-kit/icon/icon.component';
import { DesktopHeaderComponent } from './components/global/navigation/desktop/desktop-header.component';
import { CurrencySymbolComponent } from './components/currency-symbol/currency-symbol.component';
import { InjectorContainerModule } from '@shared/helpers/injector-container/injector-container.module';
import { HasPermissionDirective } from '@shared/directives/has-permission.directive';
import { UserDatePipe } from '@shared/pipes/user-date.pipe';
import { DragDropFilesUploadDirective } from '@shared/directives/drag-drop-files-upload.directive';
import { DebounceEventDirective } from '@shared/directives/debounce.directive';
import { APP_DATE_FORMATS, AppDateAdapter } from '@shared/pipes/datepicker-adapter';
import { InputWithChipsKitComponent } from './components/form-elements/ui-kit/input-with-chips/input-with-chips-kit.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { DatepickerFieldKitComponent } from './components/form-elements/ui-kit/datepicker-field/datepicker-field-kit.component';
import { MaskedInputKitComponent } from './components/form-elements/ui-kit/masked-input/masked-input-kit.component';
import { TextareaKitComponent } from './components/form-elements/ui-kit/textarea/textarea-kit.component';
import { CustomPaginationComponent } from './components/custom-pagination/custom-pagination.component';
import { TitleComponent } from './components/typography/title/title.component';
import { CreateEntityModalComponent } from './components/modals/create-entity-modal/create-entity-modal.component';
import { DefaultModalComponent } from './components/modals/default-modal/default-modal.component';
import { ToggleKitComponent } from './components/form-elements/ui-kit/toggle/toggle-kit.component';
import { EventsAutocompleteComponent } from './components/form-elements/autocomplete/events-autocomplete/events-autocomplete.component';
import { LanguagePipe } from './pipes/language.pipe';
import { EventsAutocompleteWithFiltersComponent } from './components/form-elements/autocomplete/events-autocomplete/events-autocomplete-with-filters/events-autocomplete-with-filters.component';
import { MobileOrganizationsListComponent } from './components/global/navigation/mobile/mobile-organizations-list/mobile-organizations-list.component';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { TooltipOnEllipsisComponent } from './components/form-elements/ui-kit/tooltip-kit/tooltip-on-ellipsis/tooltip-on-ellipsis.component';
import { MobileTableViewComponent } from './components/mobile-table-view/mobile-table-view.component';
import { DetailPageViewSelectorComponent } from './components/detail-page-view-selector/detail-page-view-selector.component';
import { InfoAnchorsComponent } from './components/info-block/info-anchors/info-anchors.component';
import { ImportAutocompleteComponent } from './components/form-elements/autocomplete/import-autocomplete/import-autocomplete.component';
import { CommonLayoutComponent } from '@shared/components/global/common-layout/common-layout.component';
import { SkeletonModule } from './modules/skeleton/skeleton.module';
import { TransferAutocompleteComponent } from './components/form-elements/autocomplete/transfer-autocomplete/transfer-autocomplete.component';
import { TagsAutocompleteComponent } from './components/form-elements/autocomplete/tags-autocomplete/tags-autocomplete.component';
import { BottomSheetOptionsComponent } from './components/bottom-sheet-options/bottom-sheet-options.component';
import { LayoutsComponent } from '@shared/components/global/layouts/layouts.component';
import { ModifiersAutocompleteComponent } from './components/form-elements/autocomplete/modifiers-autocomplete/modifiers-autocomplete.component';
import { LocationStateAutocompleteComponent } from './components/form-elements/autocomplete/location-state-autocomplete/location-state-autocomplete.component';
import { RegionAutocompleteComponent } from './components/form-elements/autocomplete/region-autocomplete/region-autocomplete.component';
import { DatetimeAgo } from './pipes/datetime-ago.pipe';
import { PreferenceCardsAutocompleteComponent } from './components/form-elements/autocomplete/preference-cards-autocomplete/preference-cards-autocomplete.component';
import { ItemQuantityComponent } from './components/form-elements/item-quantity/item-quantity.component';
import { BomAutocompleteComponent } from './components/form-elements/autocomplete/bom-autocomplete/bom-autocomplete.component';
import { CustomPaginationSelectorComponent } from './components/custom-pagination-selector/custom-pagination-selector.component';
import { MobileOfflineProgressModalComponent } from '@shared/components/mobile-offline-progress-modal/mobile-offline-progress-modal.component';
import { ProcessingModalComponent } from './components/modals/processing-modal/processing-modal.component';
import { PriceSheetAutocompleteComponent } from './components/form-elements/autocomplete/price-sheet-autocomplete/price-sheet-autocomplete.component';
import { ExpandedSectionComponent } from './components/expanded-section/expanded-section.component';
import { ButtonWithMenuComponent } from './components/form-elements/ui-kit/button-with-menu/button-with-menu.component';
import { QuillConfigModule } from 'ngx-quill';
import { CustomQuillConfigWithMention } from '@shared/modules/comments/constants/quill-config';
import { QuicklinkModule } from 'ngx-quicklink';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MultiselectDropdownComponent } from './components/form-elements/ui-kit/multiselect-dropdown/multiselect-dropdown.component';
import { ResponsiveClassDirective } from '@shared/directives/responsive-class.directive';
import { MessagingComponent } from '@shared/components/global/messaging/messaging.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { AiWidgetButtonComponent } from '@shared/standalone/partials/ai-widget-button/ai-widget-button.component';
const maskConfig: Partial<IConfig> = {
  validation: true
};

@NgModule({
  imports: [
    //Vendor
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    //Material, after importing new material module, check if it is required importing styles or colors in custom-theme.scss
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatRadioModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatMenuModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatStepperModule,
    MatBottomSheetModule,
    MatSidenavModule,
    //Other
    ScrollingModule,
    InjectorContainerModule,
    SkeletonModule,
    NgxMaskModule.forRoot(maskConfig),
    QuillConfigModule.forRoot(CustomQuillConfigWithMention),
    QuicklinkModule,
    NgMultiSelectDropDownModule.forRoot(),
    ResponsiveClassDirective,
    AiWidgetButtonComponent
  ],
  declarations: [
    //Autocomplete
    LocationsAutocompleteComponent,
    TransferAutocompleteComponent,
    PhysicianAutocompleteComponent,
    FacilityAutocompleteComponent,
    ProcedureAutocompleteComponent,
    ManufacturerAutocompleteComponent,
    ProductAutocompleteComponent,
    ContainerAutocompleteComponent,
    UserAutocompleteComponent,
    CatalogAutocompleteComponent,
    EventsAutocompleteComponent,
    ImportAutocompleteComponent,
    TagsAutocompleteComponent,
    ModifiersAutocompleteComponent,
    LocationStateAutocompleteComponent,
    RegionAutocompleteComponent,
    PreferenceCardsAutocompleteComponent,
    BomAutocompleteComponent,
    PriceSheetAutocompleteComponent,
    EventsAutocompleteWithFiltersComponent,
    //Form elements
    ButtonKitComponent,
    InputKitComponent,
    MaskedInputKitComponent,
    TextareaKitComponent,
    CheckboxKitComponent,
    RadioKitComponent,
    SelectKitComponent,
    InputWithSelectKitComponent,
    InputWithChipsKitComponent,
    DatepickerKitComponent,
    DatepickerFieldKitComponent,
    TooltipKitComponent,
    ToggleKitComponent,
    ButtonWithMenuComponent,
    MultiselectDropdownComponent,
    //Modals
    ProcessingModalComponent,
    ModalTooltipComponent,
    CreateEntityModalComponent,
    DefaultModalComponent,
    MobileOfflineProgressModalComponent,
    ConfirmComponent,
    //Pipe
    UserDatePipe,
    DatetimeAgo,
    LanguagePipe,
    //Directive
    HasPermissionDirective,
    DragDropFilesUploadDirective,
    DebounceEventDirective,
    //Other
    LayoutsComponent,
    GlobalLoaderComponent,
    BackPrevPageComponent,
    MobileMenuComponent,
    AttachmentsComponent,
    AssignTransferInventoryToContainerComponent,
    InventoryOptionShortModelComponent,
    InventorySelectedShortModelWithCountComponent,
    MobileScreenTitleComponent,
    DesktopHeaderComponent,
    InfoBlockComponent,
    OrganizationSwitcherComponent,
    SelectItemsCountComponent,
    TabUIComponent,
    IconComponent,
    CurrencySymbolComponent,
    CustomPaginationComponent,
    TitleComponent,
    MobileOrganizationsListComponent,
    EmptyStateComponent,
    MobileTableViewComponent,
    TooltipOnEllipsisComponent,
    DetailPageViewSelectorComponent,
    InfoAnchorsComponent,
    CommonLayoutComponent,
    BottomSheetOptionsComponent,
    ItemQuantityComponent,
    CustomPaginationSelectorComponent,
    ExpandedSectionComponent,
    MessagingComponent
  ],
  exports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatInputModule,
    ResponsiveClassDirective,
    MatTableModule,
    MatPaginatorModule,
    FormsModule,
    ReactiveFormsModule,
    MatSortModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatExpansionModule,
    MatProgressBarModule,
    BackPrevPageComponent,
    HasPermissionDirective,
    MatStepperModule,
    UserDatePipe,
    DatetimeAgo,
    MobileMenuComponent,
    AttachmentsComponent,
    AssignTransferInventoryToContainerComponent,
    InventoryOptionShortModelComponent,
    InventorySelectedShortModelWithCountComponent,
    MobileScreenTitleComponent,
    DesktopHeaderComponent,
    InjectorContainerModule,
    DragDropFilesUploadDirective,
    DebounceEventDirective,
    InfoBlockComponent,
    LocationsAutocompleteComponent,
    TransferAutocompleteComponent,
    ImportAutocompleteComponent,
    FacilityAutocompleteComponent,
    PhysicianAutocompleteComponent,
    ProcedureAutocompleteComponent,
    ManufacturerAutocompleteComponent,
    ProductAutocompleteComponent,
    ContainerAutocompleteComponent,
    UserAutocompleteComponent,
    MatPasswordStrengthModule,
    CatalogAutocompleteComponent,
    ScrollingModule,
    SelectItemsCountComponent,
    TabUIComponent,
    ButtonKitComponent,
    InputKitComponent,
    MaskedInputKitComponent,
    TextareaKitComponent,
    CheckboxKitComponent,
    RadioKitComponent,
    SelectKitComponent,
    InputWithSelectKitComponent,
    InputWithChipsKitComponent,
    DatepickerKitComponent,
    DatepickerFieldKitComponent,
    TooltipKitComponent,
    IconComponent,
    CurrencySymbolComponent,
    CustomPaginationComponent,
    TitleComponent,
    CreateEntityModalComponent,
    DefaultModalComponent,
    ToggleKitComponent,
    ModalTooltipComponent,
    EventsAutocompleteComponent,
    LanguagePipe,
    EventsAutocompleteWithFiltersComponent,
    MatBottomSheetModule,
    MobileOrganizationsListComponent,
    EmptyStateComponent,
    MobileTableViewComponent,
    TooltipOnEllipsisComponent,
    DetailPageViewSelectorComponent,
    InfoAnchorsComponent,
    TagsAutocompleteComponent,
    TagsAutocompleteComponent,
    ModifiersAutocompleteComponent,
    LocationStateAutocompleteComponent,
    RegionAutocompleteComponent,
    PreferenceCardsAutocompleteComponent,
    ItemQuantityComponent,
    SkeletonModule,
    BomAutocompleteComponent,
    CustomPaginationSelectorComponent,
    PriceSheetAutocompleteComponent,
    ExpandedSectionComponent,
    ButtonWithMenuComponent,
    QuicklinkModule,
    NgxMaskModule,
    NgMultiSelectDropDownModule,
    MultiselectDropdownComponent,
    MatSidenavModule
  ],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class SharedModule {}
