@let isMobile = isMobile$ | async;
@let transfersExist = transfersExist$ | async;
@let inventoryRequestsExists = inventoryRequestsExists$ | async;

<form
  class="form-group-emphasized"
  data-layout="column"
  data-layout-gap="24px"
  [formGroup]="required"
  [ngClass]="{ 'last-child': createEntityFlow }"
  data-cy="eventStep1Form"
>
  <app-title *ngIf="createEntityFlow" [title]="'events.detail.basicInformationLabel' | language" [type]="'h5'"></app-title>

  <div
    *ngIf="!createEntityFlow && eventDetail.id"
    class="chipsContainer"
    data-hide
    data-show-lt-lg
    data-layout="row"
    data-layout-align="start center"
    data-layout-gap="8px"
  >
    <span class="large emphasizedGray">{{ eEventTypes[eventDetail.eventType] | language }}</span>
    <span class="large emphasizedGray">{{ ('events.detail.idLabel' | language) + ' ' + (eventDetail.number || '') }}</span>

    <span *ngIf="eventDetail.eventCreditStatus" class="large emphasizedOrange">{{
      eEventCreditStatus[eventDetail.eventCreditStatus] | language
    }}</span>

    <span *ngIf="eventDetail.facility?.geographicalType === 'INTERNATIONAL'" class="large emphasizedBlue">{{
      'shared.enums.facilityGeographicalType.international' | language
    }}</span>

    <span *ngIf="eventDetail?.representative?.isOffline" class="large emphasizedRed">{{ 'offline.representativeOffline' | language }}</span>

    <ng-container *appHasPermission="{ scope: 'events', permissionName: 'canReadDUTPriceHasChangedByNonAdmin' }">
      <span *ngIf="devicesPriceChanged$ | async" class="large emphasizedOrange">
        {{ 'shared.statuses.priceEditedByUser' | language }}
      </span>
    </ng-container>

    <span *ngIf="eventDetail.facilityStockEvent" class="large emphasizedOrange">{{ 'shared.statuses.facilityStockUsed' | language }}</span>
  </div>

  <app-event-quick-actions *ngIf="!createEntityFlow" />

  <div class="form-group-emphasized-container" data-layout="column" data-layout-gap="16px">
    <div
      *ngIf="!createEntityFlow"
      #container
      data-layout="row"
      data-layout-gap="16px"
      data-layout-xs="column"
      data-layout-align-xs="start stretch"
      [attr.data-hide]="
        !inventoryRequestsExists &&
        !transfersExist &&
        !poNumbers?.length &&
        !showInvoiceNumber() &&
        !eventDetail.lastInvoiceSentDatetime &&
        !eventDetail.lastSalesOrderSentDatetime
      "
    >
      <div
        [attr.data-hide]="!inventoryRequestsExists && !transfersExist"
        data-flex="50"
        data-layout="column"
        data-layout-gap="16px"
        class="movementsContainer"
      >
        <app-title [title]="'events.detail.requestsTransfers' | language" [type]="'h5'"></app-title>

        <div
          data-layout="row"
          data-layout-gap="8px"
          data-layout-align="space-between start"
          data-layout-lt-md="column"
          data-layout-align-lt-md="start stretch"
        >
          <app-replenishment-inventory-request
            *ngIf="eventDetail?.id"
            data-flex="50"
            data-flex-lt-md="none"
            [attr.data-hide]="!inventoryRequestsExists"
          ></app-replenishment-inventory-request>

          <app-associated-transfers
            *ngIf="eventDetail?.id"
            data-flex="50"
            data-flex-lt-md="none"
            [attr.data-hide]="!transfersExist"
            [eventId]="eventDetail.id"
            (dataChangedEmitter)="transfersExist$.next($event)"
          ></app-associated-transfers>
        </div>

        <app-email-timestamp
          *ngIf="(eventDetail.eventType === 'DAMAGE' || eventDetail.eventType === 'RETURN') && eventDetail.lastSalesOrderSentDatetime"
          [label]="('events.detail.salesOrderSentLabel' | language) + ':'"
          [datetime]="eventDetail.lastSalesOrderSentDatetime"
        ></app-email-timestamp>
      </div>

      <div
        *ngIf="eventDetail.eventType !== 'DAMAGE' && eventDetail.eventType !== 'RETURN'"
        data-flex="50"
        data-layout="column"
        data-layout-gap="16px"
        [attr.data-hide]="
          !poNumbers?.length && !showInvoiceNumber() && !eventDetail.lastInvoiceSentDatetime && !eventDetail.lastSalesOrderSentDatetime
        "
      >
        <app-po-number-details [eventDetail]="eventDetail" />

        <span
          class="eventCaption default"
          *ngIf="showInvoiceNumber()"
          data-layout="row"
          data-layout-gap="4px"
          data-layout-align="start center"
        >
          <span>{{ 'events.detail.invoiceNumberLabel' | language }}:</span>
          <b data-cy="eventInvoiceNumber">{{ eventDetail?.invoiceNumber }}</b>
        </span>

        <app-email-timestamp
          *ngIf="eventDetail?.lastInvoiceSentDatetime"
          [label]="('events.detail.invoiceSentLabel' | language) + ':'"
          [datetime]="eventDetail?.lastInvoiceSentDatetime"
        ></app-email-timestamp>

        <app-email-timestamp
          *ngIf="eventDetail?.lastSalesOrderSentDatetime"
          [label]="('events.detail.salesOrderSentLabel' | language) + ':'"
          [datetime]="eventDetail?.lastSalesOrderSentDatetime"
        ></app-email-timestamp>
      </div>
    </div>

    <app-email-timestamp
      *ngIf="
        (eventDetail.eventType === 'DAMAGE' || eventDetail.eventType === 'RETURN') &&
        !inventoryRequestsExists &&
        !transfersExist &&
        eventDetail.lastSalesOrderSentDatetime
      "
      [label]="('events.detail.salesOrderSentLabel' | language) + ':'"
      [datetime]="eventDetail?.lastSalesOrderSentDatetime"
    ></app-email-timestamp>

    <div
      *ngIf="!createEntityFlow"
      class="divider"
      [attr.data-hide]="
        !inventoryRequestsExists &&
        !transfersExist &&
        !poNumbers?.length &&
        !showInvoiceNumber() &&
        !eventDetail?.lastInvoiceSentDatetime &&
        !eventDetail?.lastSalesOrderSentDatetime
      "
    ></div>

    <app-title *ngIf="!createEntityFlow" [title]="'events.detail.basicInformationLabel' | language" [type]="'h5'"></app-title>

    <div class="form-group-emphasized-2-col">
      <app-select-kit
        *ngIf="createEntityFlow || isMobile"
        [selectData]="availableEventTypes"
        [translateValue]="true"
        [dataCy]="'eventTypeSelect'"
        [optionDataCy]="'eventTypeOption'"
        [label]="'events.detail.typeLabel' | language"
        [required]="true"
        [disabled]="required.get('eventType').disabled"
        [initialValue]="required.get('eventType').value"
        [parseKeyByProperty]="'value'"
        [parseValueByProperty]="'key'"
        (selectEmitter)="required.markAsTouched(); required.get('eventType').setValue($event); changeEventType()"
      ></app-select-kit>
      <app-user-autocomplete
        *ngIf="
          (createEntityFlow || isMobile || permissionService.isRole('ADMIN')) && required.get('eventType')?.value !== 'STOCK_ADJUSTMENT'
        "
        [hideMismatchedOrgOption]="true"
        [user]="required.get('representativeId').value"
        [disabled]="required.get('representativeId').disabled || hasAssignedItems()"
        [invalid]="required.get('representativeId').invalid || required.get('representativeId').value?.id === false"
        [type]="'CUSTODY'"
        [facility]="createEntityFlow ? required.get('facilityId')?.value : null"
        [tooltip]="createEntityFlow && required.get('facilityId')?.value?.id ? 'events.detail.repsByFacility' : null"
        [label]="'events.detail.representativeLabel' | language"
        [required]="true"
        [showOpenInNewIcon]="permissionService.isGranted('events', 'canReadUsers')"
        [showInvalidStateOnInit]="
          required.get('representativeId').value?.name?.length &&
          required.get('representativeId').value?.id === false &&
          isShowInputsInvalidStateOnInit
        "
        (valueChangedEmitter)="required.get('representativeId').setValue($event)"
        (optionSelectedEmitter)="
          required.get('representativeId').setValue($event);
          required.get('representativeId').markAsTouched();
          chooseRepresentative($event);
          selectedCustodyEmitter.emit($event)
        "
      ></app-user-autocomplete>
      <app-input-kit
        formControlName="name"
        [dataCy]="'eventNameInput'"
        [initialValue]="required.get('name').value"
        [label]="
          (required.get('eventType').value === 'CASE' && createEntityFlow ? 'events.detail.nameOptional' : 'events.detail.nameLabel')
            | language
        "
        [required]="required.get('eventType').value !== 'CASE' || !createEntityFlow"
        [disabled]="required.get('name').disabled"
      ></app-input-kit>
      <app-input-kit
        *ngIf="config.anonymousPatientId"
        formControlName="healthRecordId"
        [required]="required.get('eventType').value === 'TRIAL'"
        [dataCy]="'eventPatientIdInput'"
        [initialValue]="required.get('healthRecordId').value"
        [label]="'events.detail.anonymousPatientIDLabel' | language"
        [disabled]="required.get('healthRecordId').disabled"
      ></app-input-kit>
      <app-facility-autocomplete
        *ngIf="config.facility"
        [disabled]="!required.get('eventType')?.value || !allowEdit || required.get('facilityId')?.disabled"
        [invalid]="required.get('facilityId').invalid || !required.get('facilityId').value?.id"
        [facility]="required.get('facilityId').value"
        [representative]="required.get('representativeId').value"
        [showAddNewButton]="allowCreateAndInNewIconInAutocompletes"
        [required]="
          required.get('eventType')?.value !== 'DAMAGE' &&
          required.get('eventType')?.value !== 'OTHER' &&
          required.get('eventType')?.value !== 'SAMPLE' &&
          required.get('eventType')?.value !== 'RETURN'
        "
        [showOpenInNewIcon]="allowCreateAndInNewIconInAutocompletes"
        [showClearIcon]="
          (required.get('eventType')?.value === 'DAMAGE' ||
            required.get('eventType')?.value === 'OTHER' ||
            required.get('eventType')?.value === 'SAMPLE' ||
            required.get('eventType')?.value === 'RETURN') &&
          required.get('facilityId').enabled
        "
        [showInvalidStateOnInit]="
          required.get('facilityId').value?.name?.length && !required.get('facilityId').value?.id && isShowInputsInvalidStateOnInit
        "
        (assignFacilityEmitter)="assignFacilityEmitter.emit($event)"
        (valueChangedEmitter)="required.get('facilityId').setValue($event)"
        (selectedOptionEmitter)="
          required.get('facilityId').setValue($event);
          required.get('facilityId').markAsTouched();
          required.markAsDirty();
          checkFacilityCreditHold($event);
          required.get('eventType').value === 'STOCKING_ORDER' ? setFacilityContacts($event) : null
        "
        (clearInputEmitter)="required.get('facilityId').setValue(null); required.get('facilityId').markAsTouched(); required.markAsDirty()"
      ></app-facility-autocomplete>
      <app-procedure-autocomplete
        *ngIf="config.procedure"
        [procedure]="required.get('procedureId').value"
        [physicians]="[this.required.get('physicianId')?.value]"
        [representative]="required.get('representativeId').value"
        [required]="true"
        [tooltip]="
          currentUser.organization.showProcedureAssignedToPhysician && required.get('physicianId')?.value?.id
            ? 'events.detail.proceduresByPhysician'
            : null
        "
        [invalid]="required.get('procedureId').invalid || !required.get('procedureId').value?.id"
        [disabled]="!allowEdit || required.get('procedureId').disabled"
        [showAddNewButton]="allowCreateAndInNewIconInAutocompletes"
        [showOpenInNewIcon]="allowCreateAndInNewIconInAutocompletes"
        [showInvalidStateOnInit]="
          required.get('procedureId').value?.name?.length && !required.get('procedureId').value?.id && isShowInputsInvalidStateOnInit
        "
        (valueChangedEmitter)="required.get('procedureId').setValue($event)"
        (selectedOptionEmitter)="
          required.get('procedureId').setValue($event); required.get('procedureId').markAsTouched(); setModifiers($event)
        "
      ></app-procedure-autocomplete>

      <app-physician-autocomplete
        *ngIf="config.physician"
        [autoScrollOnFocus]="createEntityFlow"
        [showOpenInNewIcon]="allowCreateAndInNewIconInAutocompletes"
        [representative]="required.get('representativeId').value"
        [physician]="required.get('physicianId').value"
        [required]="
          required.get('eventType')?.value !== 'DAMAGE' &&
          required.get('eventType')?.value !== 'OTHER' &&
          required.get('eventType')?.value !== 'SAMPLE' &&
          (required.get('eventType')?.value === 'CASE' ? !isPhysicianOptional : true)
        "
        [showAddNewButton]="allowCreateAndInNewIconInAutocompletes"
        [procedure]="required.get('procedureId').value"
        [tooltip]="
          currentUser.organization.showProcedureAssignedToPhysician && required.get('procedureId')?.value?.id
            ? 'events.detail.physiciansByProcedure'
            : null
        "
        [invalid]="required.get('physicianId').invalid || !required.get('physicianId').value?.id"
        [showInvalidStateOnInit]="
          required.get('physicianId').value?.fullName?.length && !required.get('physicianId').value?.id && isShowInputsInvalidStateOnInit
        "
        [disabled]="required.get('physicianId').disabled || !allowEdit"
        [showClearIcon]="
          (required.get('eventType')?.value === 'DAMAGE' ||
            required.get('eventType')?.value === 'OTHER' ||
            required.get('eventType')?.value === 'SAMPLE') &&
          required.get('physicianId').enabled
        "
        (selectedOptionEmitter)="required.get('physicianId').setValue($event); required.markAsTouched(); required.markAsDirty()"
        (valueChangedEmitter)="required.get('physicianId').setValue($event)"
        (assignPhysicianEmitter)="assignPhysicianEmitter.emit($event); required.markAsTouched(); required.markAsDirty()"
        (clearInputEmitter)="required.get('physicianId').setValue(null); required.markAsTouched(); required.markAsDirty()"
      ></app-physician-autocomplete>
      <app-input-kit
        *ngIf="config.description"
        formControlName="description"
        [dataCy]="'eventDescriptionInput'"
        [initialValue]="required.get('description').value"
        [label]="'events.detail.descriptionLabel' | language"
        [disabled]="required.get('description').disabled"
      ></app-input-kit>
      <app-input-kit
        *ngIf="config.protocol"
        formControlName="protocolNumber"
        [dataCy]="'eventProtocolInput'"
        [initialValue]="required.get('protocolNumber').value"
        [label]="'events.detail.protocolRMALabel' | language"
        [disabled]="required.get('protocolNumber').disabled"
      ></app-input-kit>
      <app-input-kit
        *ngIf="config.investigator"
        formControlName="principalInvestigator"
        [dataCy]="'eventInvestigatorInput'"
        [initialValue]="required.get('principalInvestigator').value"
        [label]="'events.detail.principalInvestigatorLabel' | language"
        [disabled]="required.get('principalInvestigator').disabled"
      ></app-input-kit>

      <app-modifiers-autocomplete
        *ngIf="config.procedure"
        [multiple]="true"
        [selectedChips]="modifiers"
        [autocompleteData]="{ useAutocomplete: true, data: required.get('procedureId').value?.modifiers || modifiersAutocomplete }"
        [disabled]="!required.get('procedureId').value || required.get('procedureId').disabled"
        [removable]="allowEdit"
        [label]="'events.detail.procedureModifiers'"
        (removeChipEmitter)="removeModifier($event)"
        (optionSelectedEmitter)="selectModifier($event)"
      ></app-modifiers-autocomplete>
    </div>

    <app-input-with-chips-kit
      *ngIf="config.facilityContact"
      data-flex="100"
      [showAddChipButton]="true"
      [dataCy]="'facilityContactInput'"
      [chipDataCy]="'facilityContactChip'"
      [selectedChips]="selectedFacilityContact"
      [label]="'events.detail.facilityContactsLabel' | language"
      [disabled]="required.get('facilityContact').disabled"
      [selectable]="true"
      [debounceEvents]="['click', 'focus', 'keyup']"
      [debounceTime]="0"
      [icons]="[showEditContactIcon ? 'edit' : null]"
      [autocompleteName]="autoCompleteFacilityContact"
      [required]="!selectedFacilityContact.length"
      [requiredView]="!!selectedFacilityContact.length"
      [removable]="allowEdit"
      [parseChipByProperty]="'name'"
      (debounceTimeEndedEmitter)="filterFacilityContactOption()"
      (iconClickEmitter)="doIconAction($event)"
      (chipsRemovedEmitter)="
        removeFacilityContactEmitter.emit([$event.id]); required.get('facilityContact').markAsTouched(); filterFacilityContactOption()
      "
    ></app-input-with-chips-kit>
    <mat-autocomplete
      #autoCompleteFacilityContact="matAutocomplete"
      (optionSelected)="selectFacilityContactEmitter.emit($event.option.value); required.get('facilityContact').markAsTouched()"
    >
      <mat-option *ngIf="!facilityContactFiltered.length; else data" class="connectSxAutocompleteOption" disabled
        ><span>{{ 'shared.labels.notFound' | language }}</span></mat-option
      >
      <ng-template #data>
        <mat-option
          *ngFor="let contact of facilityContactFiltered; trackBy: trackBy"
          data-cy="facilityContactOption"
          class="connectSxAutocompleteOption"
          [value]="contact"
        >
          <p>{{ contact.name || 'Untitled' }}</p>
          <small>{{ 'events.detail.emailLabel' | language }}: {{ contact.email || contact.additionalEmail || '-' }}</small>
          <small>{{ 'events.detail.phoneLabel' | language }}: {{ contact.phone || '-' }}</small>
        </mat-option>
      </ng-template>
    </mat-autocomplete>
  </div>

  <div class="form-group-emphasized-container" data-layout="column" data-layout-gap="16px">
    <div
      data-layout="row"
      data-layout-gap="16px"
      data-layout-align="space-between center"
      data-layout-xs="column"
      data-layout-align-xs="start stretch"
    >
      <app-title
        [title]="('events.detail.eventDateLabel' | language) + (showTime ? ' ' + ('events.detail.andTimeLabel' | language) : '')"
        [type]="createEntityFlow ? 'caption' : 'h5'"
      ></app-title>
      <app-button-kit
        *ngIf="!createEntityFlow && required.get('eventType').value !== 'STOCKING_ORDER'"
        [title]="'events.detail.addToCalendarLabel' | language"
        [type]="'secondary'"
        [icon]="isMobile ? null : 'plus'"
        [size]="isMobile ? 'medium' : 'small'"
        (clickEmitter)="downloadEvent()"
      ></app-button-kit>
    </div>
    <div *ngIf="showRepTime && !required.disabled && showTime" data-layout="row" data-layout-gap="16px" data-layout-align="start center">
      <span class="eventsHint">{{ 'events.detail.useRepresentativeTZLabel' | language }}</span>
      <app-toggle-kit [initialValue]="!isActiveLocalTime" (toggleEmitter)="swapDateTime()"></app-toggle-kit>
    </div>
    <div data-layout="row" data-layout-gap="16px" data-layout-xs="column">
      <app-datepicker-field-kit
        data-flex="50"
        data-flex-xs="100"
        [label]="showRepTime && showTime ? ('events.detail.yourLocalDateLabel' | language) : ('events.detail.dateLabel' | language)"
        [initialValue]="required.get('date').value"
        [icon]="'calendar'"
        [required]="true"
        [disabled]="required.get('date').disabled"
        [inputDataCy]="'eventDateInput'"
        (dateEmitter)="
          required.get('date').setValue($event.value); required.get('date').markAsTouched(); required.markAsDirty(); changeDate($event)
        "
      ></app-datepicker-field-kit>
      <div data-flex="50" data-flex-xs="100" data-layout="row" data-layout-gap="16px" data-layout-align="start center">
        <app-input-kit
          data-flex="100"
          *ngIf="showTime"
          formControlName="time"
          [type]="'time'"
          [dataCy]="'eventTimeInput'"
          [initialValue]="required.get('time').value"
          [label]="showRepTime ? ('events.detail.yourLocalTimeLabel' | language) : ('events.detail.timeLabel' | language)"
          [disabled]="required.get('time').disabled"
          [icons]="[required.get('time').value && required.get('time').enabled && allowEdit && showTime ? 'close' : null]"
          (iconClickEmitter)="doIconAction($event)"
          (inputChangedEmitter)="changeTime($event)"
        ></app-input-kit>
        <app-tooltip-kit class="eventTooltipContainer" *ngIf="showRepTime && showTime" [position]="'before'" [isHtml]="true">
          {{
            ('events.detail.transformedTimeCaption_1' | language) +
              ' ' +
              userTimeZone.timeZone +
              ' (' +
              userTimeZone.offset / 60 / 60 +
              'h), ' +
              ('events.detail.transformedTimeCaption_2' | language)
          }}
        </app-tooltip-kit>
      </div>
    </div>
    <div data-layout="row" data-layout-gap="16px" data-layout-xs="column" [hidden]="!showTime || !showRepTime">
      <app-datepicker-field-kit
        data-flex="50"
        data-flex-xs="100"
        [label]="'events.detail.representativeDateLabel' | language"
        [initialValue]="required.get('representativeDate').value"
        [icon]="'calendar'"
        [required]="true"
        [disabled]="required.get('representativeDate').disabled"
        (dateEmitter)="
          required.get('representativeDate').setValue($event.value);
          required.get('representativeDate').markAsTouched();
          required.markAsDirty();
          changeRepresentativeDate($event)
        "
      ></app-datepicker-field-kit>

      <div data-flex="50" data-flex-xs="100" data-layout="row" data-layout-align="start center" data-layout-gap="16px">
        <app-input-kit
          data-flex="100"
          formControlName="representativeTime"
          [type]="'time'"
          [initialValue]="required.get('representativeTime').value"
          [label]="'events.detail.representativeTimeLabel' | language"
          [disabled]="required.get('representativeTime').disabled"
          [icons]="[
            required.get('representativeTime').value && required.get('representativeTime').enabled && allowEdit && showTime ? 'close' : null
          ]"
          (iconClickEmitter)="doIconAction($event, true)"
          (inputChangedEmitter)="changeRepresentativeTime($event)"
        ></app-input-kit>

        <app-tooltip-kit class="eventTooltipContainer" [position]="'before'" [isHtml]="true">
          {{
            ('events.detail.representativeTimeCaption_1' | language) +
              ' ' +
              eventTimeZone.timeZone +
              ' (' +
              eventTimeZone.offset / 60 / 60 +
              'h). ' +
              ('events.detail.representativeTimeCaption_2' | language)
          }}
        </app-tooltip-kit>
      </div>
    </div>
  </div>
</form>
