<div>
  <app-input-kit
    data-flex="100"
    class="full-width"
    [required]="required"
    [initialValue]="import"
    [label]="label"
    [dataCy]="'importInput'"
    [debounceEvents]="['keyup', 'focus']"
    [debounceTime]="1000"
    [disabled]="disabled"
    [forceBlurEvent]="forceBlurEvent"
    [icons]="[showClearIcon ? 'close' : null]"
    [filterView]="filterView"
    [autocompleteName]="importAutocomplete"
    (debounceTimeEndedEmitter)="searchImport($event)"
    (valueChangedEmitter)="import = $event"
    (iconClickEmitter)="doIconAction($event)"
  ></app-input-kit>

  <mat-autocomplete #importAutocomplete="matAutocomplete" data-flex-align="center" [displayWith]="displayFn">
    <mat-option *ngIf="(loading$ | async) === true || (initialLoading$ | async) === true" class="is-loading" disabled>
      <mat-progress-spinner value="30" class="spinner" mode="indeterminate" diameter="30"></mat-progress-spinner>
    </mat-option>
    <ng-container *ngIf="(loading$ | async) === false && (initialLoading$ | async) === false">
      <cdk-virtual-scroll-viewport
        itemSize="0"
        class="virtualAutocompleteScroll"
        [style.height.px]="(searchedData$ | async).length < 3 ? (searchedData$ | async).length * 50 : 150"
      >
        <mat-option
          *cdkVirtualFor="let imp of searchedData$ | async"
          data-cy="importOption"
          class="connectSxAutocompleteOption"
          [value]="imp.id"
          (click)="selectOption(imp)"
        >
          <span>{{ (imp.importName || imp.fileName) + ' (' + (imp.datetimeStart | userDate) + ')' }}</span>
        </mat-option>

        <!-- Not found option -->
        <mat-option *ngIf="!(searchedData$ | async).length" disabled class="connectSxAutocompleteOption">
          <span>{{ 'shared.labels.noItemsFound' | language }}</span>
        </mat-option>
      </cdk-virtual-scroll-viewport>
    </ng-container>
  </mat-autocomplete>
</div>
