import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { UsersService } from '@services/users.service';
import { BehaviorSubject, finalize, take } from 'rxjs';
import { FacilityModel, RegionModel, UserModel } from '@shared/models';
import { DisplayName } from '@shared/utils/form-elements/display-with-autocomplete';
import { TIcons, TRoles } from '@shared/type/index.type';
import { UsersPageableParams } from '@shared/models/build-models/pageable/users-pageable-params';
import { IFilter } from '@shared/interfaces';
import { TranslationsKeys } from '@shared/type/i18n.type';
import { LanguageService } from '@services/internal/language.service';
import { FilterUniqueArray, FilterUniqueArrayIdsString } from '@shared/utils/arrays/filter-unique-array';
import { PermissionService } from '@services/internal/permission.service';
import { UserSubordinationService } from '@services/user-subordination.service';
import { ERoles } from '@shared/enum';
import { UserQuickTransferConnectionService } from '../../../../../features/users/detail-user/services/user-quick-transfer-connection.service';
import { DefaultAutoCompleteSize } from '@constants';

@Component({
  selector: 'app-user-autocomplete',
  templateUrl: './user-autocomplete.component.html',
  styleUrls: ['./user-autocomplete.component.scss'],
  standalone: false
})
export class UserAutocompleteComponent {
  @Input() user: UserModel = new UserModel();
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() invalid: boolean = false;
  @Input() label: string = LanguageService.instant('shared.components.representative');
  @Input() showOpenInNewIcon: boolean = false;
  @Input() tooltip: TranslationsKeys;
  @Input() showClearIcon: boolean = false;
  @Input() filterView: boolean = false;
  @Input() roles: TRoles[] = [];
  @Input() inputSize: 'medium' | 'small' = 'medium';
  @Input() multiple: boolean = false;
  @Input() selectedChips: UserModel[] = [];
  @Input() removable: boolean = false;
  @Input() facility: FacilityModel = new FacilityModel();
  @Input() hasDistributorGroup: boolean = null;
  @Input() clearAfterSelection: boolean = false;
  @Input() regions: RegionModel[] = [];
  @Input() type: 'INDIVIDUAL' | 'CUSTODY' | 'SENDER' = null;
  @Input() hideUserIds: string[] = [];
  @Input() distributor: UserModel = new UserModel();
  @Input() showUserRole: boolean = false;
  @Input() allowViewDetails: boolean = true;
  @Input() showFromOrgOption: boolean = false;
  @Input() usersForMentions: boolean = false;
  @Input() maxChipsCount: number = 50;
  // For non admins
  @Input() withQuickTransferUsers: boolean = false;
  // Works for ADMIN users only
  @Input() hideMismatchedOrgOption: boolean = false;
  @Input() hideOrganizationOption: boolean = false;
  @Input() hideOfflineUsers: boolean = false;
  @Input() isCustodianOnly: boolean = null;
  @Input() showInvalidStateOnInit: boolean = false;
  @Input() userIdToGetAssignedCustodianOnly: string = null;
  @Input() isShowAdminsAsOrgCustodianView: boolean = false;

  @Output() optionSelectedEmitter = new EventEmitter<UserModel>();
  @Output() clearInputEmitter = new EventEmitter<void>();
  @Output() removeChipEmitter = new EventEmitter<string>();
  @Output() valueChangedEmitter = new EventEmitter<string>();

  private userQuickTransferConnectionService = inject(UserQuickTransferConnectionService);
  private usersService = inject(UsersService);
  private permissionService = inject(PermissionService);
  private userSubordinationService = inject(UserSubordinationService);

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  initialLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  searchedData: UserModel[] = [];
  forceBlurEvent: boolean = false;

  readonly currentUser: UserModel = UsersService.getUser();
  readonly displayFn = DisplayName;
  readonly eRoleTypes: Record<string, TranslationsKeys> = ERoles;

  searchUser(value: string, skipSelectSingleOption: boolean = false): void {
    this.initialLoading$.next(false);
    this.forceBlurEvent = false;

    if (this.loading$.value) {
      return;
    }

    this.loading$.next(true);

    if (this.userIdToGetAssignedCustodianOnly) {
      this.getAssignedQuickTransferUsers(value, skipSelectSingleOption);
      return;
    }

    const params: IFilter = {
      size: DefaultAutoCompleteSize,
      state: 'ACTIVE',
      isCustodianOnly: this.isCustodianOnly,
      sort: 'name,asc'
    };

    const queryParams: UsersPageableParams = new UsersPageableParams(params);

    if (value) {
      queryParams.name = value;
    }

    if (this.usersForMentions) {
      this.getUsersForMentions(queryParams);
      return;
    }

    if (this.roles.length) {
      queryParams.roles = this.roles;
    }

    if (this.regions.length) {
      queryParams.regionIds = this.regions.map(r => r.id);
    }

    // An exception for LockDown Medical
    if (this.hasDistributorGroup !== null && this.currentUser.organization.id !== '0fd777a8-825f-4a02-a06a-b3e1a3500335') {
      queryParams.hasDistributorGroup = this.hasDistributorGroup;
    }

    if (this.facility?.id) {
      this.getUsersByFacility(value, skipSelectSingleOption);
      return;
    }

    if (this.distributor?.id) {
      this.getSubordinators(skipSelectSingleOption);
      return;
    }

    if (this.type === 'INDIVIDUAL') {
      this.getIndividuals(queryParams, skipSelectSingleOption);
      return;
    }

    if (this.type === 'CUSTODY') {
      this.getCustodies(value, queryParams, skipSelectSingleOption);
      return;
    }

    if (this.type === 'SENDER') {
      this.getSenders(queryParams);
      return;
    }

    this.usersService
      .getAllUsersPageable(queryParams)
      .pipe(take(1))
      .subscribe(data => {
        this.searchedData = FilterUniqueArray(data.content || [], this.selectedChips);
        if (this.hideUserIds.length) {
          this.searchedData = FilterUniqueArrayIdsString(this.searchedData, this.hideUserIds);
        }
        this.selectSingleOption(value, skipSelectSingleOption);
        this.loading$.next(false);
      });
  }

  doIconAction(icon: TIcons): void {
    if (icon === 'close') {
      this.clearInputEmitter.emit();
      this.searchUser('', true);
    }
  }

  selectOption(user: UserModel): void {
    if (this.clearAfterSelection) {
      this.user = new UserModel();
    }
    this.optionSelectedEmitter.emit(user);
    if (this.multiple) {
      //Hide selected item from search results
      this.searchedData = this.searchedData.filter(c => c.id !== user.id);
    }
  }

  selectOrg(): void {
    const organizationCustody: UserModel = new UserModel();
    organizationCustody.name = 'Organization';
    organizationCustody.id = null;
    this.optionSelectedEmitter.emit(organizationCustody);
  }

  removeChip(id: string): void {
    this.removeChipEmitter.emit(id);
  }

  private getSubordinators(skipSelectSingleOption: boolean): void {
    this.userSubordinationService
      .getSubordinations([this.distributor.id])
      .pipe(take(1))
      .subscribe(data => {
        this.searchedData = (data[this.distributor.id] || []).map(d => ({ ...new UserModel(), ...d.user }));
        this.selectSingleOption('', skipSelectSingleOption);
        this.loading$.next(false);
      });
  }

  private getCustodies(value: string, queryParams: UsersPageableParams, skipSelectSingleOption: boolean): void {
    const params: UsersPageableParams & { withQuickTransferUsers?: boolean } = this.withQuickTransferUsers
      ? { ...queryParams, ...{ withQuickTransferUsers: this.withQuickTransferUsers } }
      : queryParams;
    this.usersService
      .getCustody(params)
      .pipe(take(1))
      .subscribe(data => {
        this.searchedData = FilterUniqueArray(data.content || [], this.selectedChips);

        if (this.hideUserIds.length) {
          this.searchedData = FilterUniqueArrayIdsString(this.searchedData, this.hideUserIds);
        }

        // Hide organization option
        if (this.hideOrganizationOption) {
          this.searchedData = this.searchedData.filter(option => option.id);
        }

        // Show organization option only if contains the search query
        if (this.hideMismatchedOrgOption && this.permissionService.isRole('ADMIN') && value) {
          const companyName = UsersService.getCompanyName();
          this.searchedData = this.searchedData.filter((option, index) => {
            if (index === 0) {
              if (!option.id && option.name === companyName && option.name.toLowerCase().includes(value.toLowerCase())) {
                return option;
              } else {
                return null;
              }
            } else {
              return option;
            }
          });
        }

        this.selectSingleOption(value, skipSelectSingleOption);
        this.loading$.next(false);
      });
  }

  private getUsersByFacility(value: string, skipSelectSingleOption: boolean): void {
    this.usersService
      .getUsersByFacility(this.facility.id)
      .pipe(take(1))
      .subscribe(data => {
        this.searchedData = data || [];

        if (value) {
          this.searchedData = this.searchedData.filter(u => u.name.toLowerCase().includes(value.toLowerCase()));
        }

        this.selectSingleOption(value, skipSelectSingleOption);
        this.loading$.next(false);
      });
  }

  private getIndividuals(queryParams: UsersPageableParams, skipSelectSingleOption: boolean): void {
    this.usersService
      .getIndividualsPageable(queryParams)
      .pipe(
        take(1),
        finalize(() => this.loading$.next(false))
      )
      .subscribe(data => {
        this.searchedData = data.content || [];

        if (this.hideUserIds.length) {
          this.searchedData = FilterUniqueArrayIdsString(this.searchedData, this.hideUserIds);
        }

        this.selectSingleOption(queryParams.name, skipSelectSingleOption);
      });
  }

  private getSenders(queryParams: UsersPageableParams): void {
    delete queryParams.page;

    this.usersService
      .getTransferSenders(queryParams)
      .pipe(
        take(1),
        finalize(() => this.loading$.next(false))
      )
      .subscribe(data => {
        // Hide offline users
        this.searchedData = (data.content || []).filter(u => !u.isOffline);
      });
  }

  private getUsersForMentions(queryParams: UsersPageableParams): void {
    queryParams.name = queryParams.name || '';
    UsersService.getUsersForMentions(queryParams)
      .pipe(
        take(1),
        finalize(() => this.loading$.next(false))
      )
      .subscribe(data => (this.searchedData = FilterUniqueArray(data.content || [], this.selectedChips)));
  }

  private selectSingleOption(value: string, skipSelectSingleOption: boolean = false): void {
    if (skipSelectSingleOption && this.searchedData.length === 1) {
      this.forceBlurEvent = true;
      return;
    }

    if (!value && this.searchedData.length === 1 && this.required) {
      this.selectOption(this.searchedData[0]);
      this.forceBlurEvent = true;
    } else {
      this.forceBlurEvent = false;
    }
  }

  private getAssignedQuickTransferUsers(value: string, skipSelectSingleOption: boolean = false): void {
    this.userQuickTransferConnectionService
      .getAssignedQuickTransferUsers(this.userIdToGetAssignedCustodianOnly, value)
      .pipe(
        take(1),
        finalize(() => this.loading$.next(false))
      )
      .subscribe(users => {
        this.searchedData = users;
        this.selectSingleOption(value, skipSelectSingleOption);
      });
  }
}
