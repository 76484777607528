import { inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmModalConfig, CreateMediumEntityConfig, CreateSmallEntityConfig } from '@constants';
import { ContactModel, EventModel, FacilityModel, ManufacturerModel, PONumberModel } from '@shared/models';
import { BehaviorSubject, finalize, firstValueFrom, take } from 'rxjs';
import { EventPdfService } from '@services/event-pdf.service';
import { EventsState } from '../helpers/event.store.model';
import { LanguageService } from '@services/internal/language.service';
import { ConfirmComponent } from '@shared/components/modals/confirm/confirm.component';
import { ModalEditContactsComponent } from 'src/app/features/directory/facilities/partials/modals/modal-edit-contacts/modal-edit-contacts.component';
import { FacilityService } from '@services/facility.service';
import { IEventPDFSendRequest } from '@shared/interfaces';
import { TranslationsKeys } from '@shared/type/i18n.type';
import { ChooseManufacturerModalComponent } from '../partials/event-detail/modals/choose-manufacturer-modal/choose-manufacturer-modal.component';
import { EventsService } from '@services/events.service';
import { ManagerSearchParamsService } from '@shared/helpers/managaer-search-params/manager-search-params.service';
import { PageEvent } from '@angular/material/paginator';

@Injectable({
  providedIn: 'root'
})
export class EventEmailPdfService {
  private eventPdfService = inject(EventPdfService);
  private dialog = inject(MatDialog);
  private facilityService = inject(FacilityService);
  private eventsService = inject(EventsService);
  private managerSearchParamsService = inject(ManagerSearchParamsService);
  // Used for disabling buttons after the first modal appeared
  static loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // Used for displaying loader during the request to the back-end
  static sending$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  async getRecipientManufacturers(
    manufacturers: ManufacturerModel[],
    allowSelectAll: boolean = false,
    title: TranslationsKeys = null
  ): Promise<string[]> {
    if (manufacturers.length === 1) {
      return [manufacturers[0]?.id];
    } else {
      const dialogConfig: MatDialogConfig = { ...CreateSmallEntityConfig };
      await import('../../event-detail/event-detail.module');
      dialogConfig.height = 'auto';
      dialogConfig.data = { manufacturers, allowSelectAll, title };
      return await firstValueFrom(this.dialog.open(ChooseManufacturerModalComponent, dialogConfig).afterClosed());
    }
  }

  async sendInvoiceViaEmail(
    manufacturerIds: string[],
    event: EventModel,
    poNumbers: PONumberModel[],
    pagination?: PageEvent
  ): Promise<void> {
    if (!manufacturerIds?.length) {
      EventEmailPdfService.loading$.next(false);
      return;
    }

    const facilityContacts: ContactModel[] = event.facility ? event.facility.facilityContacts : [];
    const dialogConfig: MatDialogConfig = { ...CreateSmallEntityConfig };

    dialogConfig.height = 'auto';
    dialogConfig.data = {
      event,
      type: 'INVOICE',
      poDate: poNumbers.length ? poNumbers[0].poDate : null,
      poNumbers: poNumbers.length ? [poNumbers.find(p => p.manufacturer.id === manufacturerIds[0])?.poNumber || ''] : null,
      facilityContacts
    };
    const sendPdfAsAttachmentComponent = await import(
      '../partials/event-detail/modals/send-pdf-as-attachment/send-pdf-as-attachment.component'
    ).then(c => c.SendPdfAsAttachmentComponent);

    this.dialog
      .open(sendPdfAsAttachmentComponent, dialogConfig)
      .afterClosed()
      .pipe(take(1))
      .subscribe(params => {
        EventEmailPdfService.loading$.next(false);

        if (params) {
          params.manufacturerIds = manufacturerIds;
          EventEmailPdfService.sending$.next(true);
          this.eventPdfService
            .sendEmailInvoice(params)
            .pipe(
              take(1),
              finalize(() => EventEmailPdfService.sending$.next(false))
            )
            .subscribe(() => {
              if (pagination) {
                this.managerSearchParamsService.getPageable(pagination);
              }
            });
        }
      });
  }

  async sendQuoteViaEmail(manufacturerIds: string[], eventStore: EventsState): Promise<void> {
    if (!manufacturerIds?.length) {
      return;
    }

    const facilityContacts: ContactModel[] = eventStore.event.facility ? eventStore.event.facility.facilityContacts : [];
    const dialogConfig: MatDialogConfig = { ...CreateSmallEntityConfig };

    dialogConfig.height = 'auto';
    dialogConfig.data = {
      event: eventStore.event,
      type: 'EVENT_QUOTE',
      facilityContacts
    };
    const sendPdfAsAttachmentComponent = await import(
      '../partials/event-detail/modals/send-pdf-as-attachment/send-pdf-as-attachment.component'
    ).then(c => c.SendPdfAsAttachmentComponent);

    this.dialog
      .open(sendPdfAsAttachmentComponent, dialogConfig)
      .afterClosed()
      .pipe(take(1))
      .subscribe(params => {
        if (params) {
          params.manufacturerIds = manufacturerIds;
          this.eventPdfService.sendQuote(params).pipe(take(1)).subscribe();
        }
      });
  }

  // Here can be only async/await and not observables because of the flow: SO Email -> Add contact -> Request replenishment
  async sendSalesOrderFormAsEmail(manufacturerIds: string[], event: EventModel, pagination?: PageEvent): Promise<void> {
    if (!manufacturerIds?.length) {
      EventEmailPdfService.loading$.next(false);
      return;
    }

    let facilityContacts = event.facility?.facilityContacts || [];
    const dialogConfig: MatDialogConfig = { ...CreateSmallEntityConfig };

    dialogConfig.height = 'auto';
    dialogConfig.data = {
      event,
      type: 'SALES_ORDER_FORM',
      facilityContacts
    };

    const sendPdfAsAttachmentComponent = await import(
      '../partials/event-detail/modals/send-pdf-as-attachment/send-pdf-as-attachment.component'
    ).then(c => c.SendPdfAsAttachmentComponent);

    const params: IEventPDFSendRequest & { facilityContacts: ContactModel[] } = await firstValueFrom(
      this.dialog.open(sendPdfAsAttachmentComponent, dialogConfig).afterClosed()
    );

    EventEmailPdfService.loading$.next(false);

    if (params) {
      params.manufacturerIds = manufacturerIds;
      EventEmailPdfService.sending$.next(true);
      this.eventPdfService
        .sendSalesOrderForm(params)
        .pipe(
          take(1),
          finalize(() => EventEmailPdfService.sending$.next(false))
        )
        .subscribe(() => {
          if (pagination) {
            this.managerSearchParamsService.getPageable(pagination);
          }
        });

      if (params.facilityContacts) {
        facilityContacts = params.facilityContacts;
        event.facility.facilityContacts = facilityContacts;
      }

      if (
        facilityContacts.every(c => c.email !== params.email && c.additionalEmail !== params.email) &&
        event.facility?.id &&
        facilityContacts.length < 7
      ) {
        await this.addContacts(params.email, event);
      }
    }
  }

  // Used for sending the invoice from events list/board
  sendInvoiceByEvent(event: EventModel, pagination: PageEvent): void {
    EventEmailPdfService.loading$.next(true);
    this.eventsService
      .getManufacturers(event.id)
      .pipe(take(1))
      .subscribe(manufacturers => {
        this.eventsService
          .getPoNumbers(event.id)
          .pipe(take(1))
          .subscribe(async poNumbers => {
            const manufacturerIds: string[] = await this.getRecipientManufacturers(manufacturers);
            this.sendInvoiceViaEmail(manufacturerIds, event, poNumbers, pagination);
          });
      });
  }

  // Used for sending the sales order from events list/board
  sendSalesOrderByEvent(event: EventModel, pagination: PageEvent): void {
    EventEmailPdfService.loading$.next(true);
    this.eventsService
      .getManufacturers(event.id)
      .pipe(take(1))
      .subscribe(async manufacturers => {
        const manufacturerIds: string[] = await this.getRecipientManufacturers(
          manufacturers,
          true,
          'events.detail.sendAsSalesOrderFormAsAttachmentLabel'
        );
        await this.sendSalesOrderFormAsEmail(manufacturerIds, event, pagination);
      });
  }

  // Here can be only async/await and not observables because of the flow: SO Email -> Add contact -> Request replenishment
  private async addContacts(email: string, event: EventModel): Promise<void> {
    const confirmed: boolean = await firstValueFrom(
      this.dialog
        .open(
          ConfirmComponent,
          ConfirmModalConfig({
            description: LanguageService.instant('shared.alerts.prompt.addContact'),
            title: LanguageService.instant('shared.alerts.prompt.addContactTitle'),
            acceptButtonText: LanguageService.instant('shared.labels.yes'),
            declineButtonText: LanguageService.instant('shared.labels.no')
          })
        )
        .afterClosed()
    );

    if (!confirmed) {
      return;
    }
    await import('../../../directory/facilities/facilities.module');
    const dialogConfig: MatDialogConfig = { ...CreateMediumEntityConfig };

    dialogConfig.data = {
      facility: event.facility,
      newEmail: email
    };

    const params: { updateContacts: boolean; facility: FacilityModel } = await firstValueFrom(
      this.dialog.open(ModalEditContactsComponent, dialogConfig).afterClosed()
    );

    if (params?.updateContacts) {
      this.facilityService.updateFacility(event.facility.id, params.facility).pipe(take(1)).subscribe();
    }
  }
}
