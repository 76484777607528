import { ChangeDetectorRef, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs';
import { DestroySubscriptions } from '@shared/classes/destroy-subscriptions';
import { PhysicianModel, UserModel } from '@shared/models';
import { TPhoneFormat } from '@shared/type/index.type';
import { EPhoneFormat } from '@shared/enum';
import { checkPhoneFormat } from '@shared/utils/phone/check-phone-format';
import { EmailPattern } from '@shared/validators/email-validator';
import { UsersService } from '@services/users.service';
import { LocationPlaceModel } from '@shared/models/features/movement/location/location-place.model';

@Component({
  selector: 'app-form-contacts',
  templateUrl: './form-contacts.component.html',
  styleUrls: ['./form-contacts.component.scss'],
  standalone: false
})
export class FormContactsComponent extends DestroySubscriptions implements OnInit {
  @Input() set physician(value: PhysicianModel) {
    this.setValue(value);
  }
  @Input() createEntityFlow: boolean = false;

  @Output() formNameValueChangeEmitter = new EventEmitter<UntypedFormGroup>();

  private formBuilder = inject(UntypedFormBuilder);
  private ref = inject(ChangeDetectorRef);

  formContacts: UntypedFormGroup;
  primaryPhoneFormat: TPhoneFormat = 'US';
  secondaryPhoneFormat: TPhoneFormat = 'US';

  readonly currentUser: UserModel = UsersService.getUser();
  readonly phoneFormat: Record<string, string> = EPhoneFormat;

  constructor() {
    super();
    this.createForm();
  }

  ngOnInit(): void {
    this.formChanges();
    this.checkPrimaryPhoneFormat();
    this.checkSecondaryPhoneFormat();
  }

  checkPrimaryPhoneFormat(): void {
    checkPhoneFormat(this.formContacts, this.primaryPhoneFormat, 'primaryPhone');
  }

  checkSecondaryPhoneFormat(): void {
    checkPhoneFormat(this.formContacts, this.secondaryPhoneFormat, 'secondaryPhone');
  }

  onGooglePlacesAutocomplete(location: LocationPlaceModel) {
    setTimeout(() => {
      this.formContacts.patchValue({
        city: location.city,
        country: location.country,
        addressState: location.locationState,
        zipCode: location.postalCode,
        primaryLocation: location.locationName,
        googlePlaceId: location.placeId
      });
      this.formContacts.markAsTouched();
    });
  }

  private createForm(): void {
    this.formContacts = this.formBuilder.group(
      {
        city: ['', [Validators.required, Validators.maxLength(255)]],
        addressState: ['', [Validators.maxLength(255)]],
        zipCode: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
        primaryLocation: ['', [Validators.maxLength(255)]],
        primaryPhone: ['', [Validators.pattern('^[0-9]*$'), Validators.maxLength(255)]],
        secondaryPhone: ['', [Validators.pattern('^[0-9]*$'), Validators.maxLength(255)]],
        email: ['', [Validators.pattern(EmailPattern), Validators.maxLength(255)]],
        npi: ['', [Validators.maxLength(255)]],
        country: ['', [Validators.maxLength(255)]],
        optionalAddress: ['', [Validators.maxLength(255)]],
        externalId: ['', [Validators.maxLength(255)]],
        googlePlaceId: ['', [Validators.maxLength(255)]]
      },
      { emitEvent: false }
    );
  }

  private formChanges(): void {
    setTimeout(() => {
      this.formNameValueChangeEmitter.emit(this.formContacts);
    });
    this.formContacts.valueChanges.pipe(takeUntil(this.subscriptions)).subscribe(() => {
      this.formNameValueChangeEmitter.emit(this.formContacts);
    });
  }

  private setValue(data: PhysicianModel): void {
    if (!data.firstName) {
      return;
    }
    this.formContacts.setValue({
      city: data.city,
      addressState: data.addressState,
      zipCode: data.zipCode,
      primaryLocation: data.primaryLocation,
      primaryPhone: data.primaryPhone,
      secondaryPhone: data.secondaryPhone,
      email: data.email,
      npi: data.npi,
      country: data.country,
      optionalAddress: data.optionalAddress,
      externalId: data.externalId,
      googlePlaceId: data.googlePlaceId
    });
    this.primaryPhoneFormat = data.primaryPhone?.length >= 11 ? 'INTERNATIONAL' : 'US';
    this.secondaryPhoneFormat = data.secondaryPhone?.length >= 11 ? 'INTERNATIONAL' : 'US';
    this.checkPrimaryPhoneFormat();
    this.checkSecondaryPhoneFormat();
    setTimeout(() => this.ref.markForCheck());
  }
}
