import { UsersService } from '@services/users.service';
import { UsersPageableParams } from '@shared/models/build-models/pageable/users-pageable-params';
import { FilterUniqueArray } from '@shared/utils/arrays/filter-unique-array';
import { QuillConfig } from 'ngx-quill';
import { take } from 'rxjs';
import { ParseUserIds } from '../utils/parse-user-ids';

export const CustomQuillConfigWithMention: QuillConfig = {
  placeholder: 'Type @ to mention and notify someone.',
  sanitize: true,
  debug: false,
  modules: {
    'emoji-toolbar': true,
    mention: {
      allowedChars: /^[a-zA-Z0-9_ ]*$/,
      mentionDenotationChars: ['@'],
      source(search: string, renderList: (data: { id: string; value: string }[]) => void) {
        const params: UsersPageableParams = {
          page: 0,
          sort: ['name,asc'],
          size: 20,
          name: search
        };

        const editorValue: string = document.querySelector('.ql-editor p').innerHTML;
        const mentionedUsers: { id: string; value: string }[] = ParseUserIds(editorValue).map(userId => ({ id: userId, value: '' }));

        UsersService.getUsersForMentions(params)
          .pipe(take(1))
          .subscribe(data => {
            const searchedData: { id: string; value: string }[] = data.content
              .filter(u => u.id !== UsersService.getUser().id)
              .map(user => ({ id: user.id, value: user.name }));
            renderList(FilterUniqueArray(searchedData, mentionedUsers));
          });
      }
    },
    toolbar: [
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ color: [] }, { background: [] }],
      ['emoji']
    ]
  }
};

export const CustomQuillConfig: QuillConfig = {
  placeholder: 'Add a note here',
  sanitize: true,
  debug: false,
  modules: {
    'emoji-toolbar': true,
    toolbar: [
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ color: [] }, { background: [] }],
      ['emoji']
    ]
  }
};
