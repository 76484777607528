import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BillOfMaterialsService } from '@services/bill-of-materials.service';
import { CatalogService } from '@services/catalog.service';
import { BillOfMaterials, CatalogModel, ManufacturerModel, ProductLineModel, PreferenceCardAssignment } from '@shared/models';
import { BehaviorSubject, Observable, finalize, forkJoin, take, tap } from 'rxjs';

@Component({
  selector: 'app-assignments-from-bom',
  templateUrl: './assignments-from-bom.component.html',
  styleUrls: ['./assignments-from-bom.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class AssignmentsFromBomComponent {
  private dialogRef = inject(MatDialogRef<AssignmentsFromBomComponent>);
  private catalogService = inject(CatalogService);
  private billOfMaterialsService = inject(BillOfMaterialsService);

  bom: BillOfMaterials = new BillOfMaterials();
  manufacturerId: string = '';
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  manufacturers: ManufacturerModel[] = [];
  recipeAssignments: PreferenceCardAssignment[] = [];
  bomCatalogs: CatalogModel[] = [];

  clear(entity: 'BOM'): void {
    if (entity === 'BOM') {
      this.bom = new BillOfMaterials();
    }
  }

  submit(): void {
    this.loading$.next(true);

    const requests: Observable<ProductLineModel[]>[] = [];

    this.recipeAssignments.forEach(assignment => {
      requests.push(
        this.catalogService.getProducts(assignment.catalog.id).pipe(
          take(1),
          tap(products => (assignment.product = products[0]))
        )
      );
    });

    forkJoin(requests)
      .pipe(finalize(() => this.loading$.next(false)))
      .subscribe(() => {
        this.dialogRef.close(this.recipeAssignments);
      });
  }

  getBOMAssignments(bom: BillOfMaterials): void {
    // Reset the old data
    this.recipeAssignments = [];
    this.manufacturerId = '';
    this.manufacturers = [];
    this.bomCatalogs = [];

    // Set the new data
    this.bom = bom;
    this.loading$.next(true);
    this.billOfMaterialsService
      .getDetails(this.bom.id)
      .pipe(
        take(1),
        finalize(() => this.loading$.next(false))
      )
      .subscribe(data => {
        this.bomCatalogs = data.map(d => d.catalog);
        this.bomCatalogs.forEach(c => {
          if (!this.manufacturers.some(m => m.id === c.manufacturer.id)) {
            this.manufacturers.push(c.manufacturer);
          }
        });

        if (this.manufacturers.length === 1) {
          this.selectManufacturer(this.manufacturers[0].id);
        }
      });
  }

  selectManufacturer(manufacturerId: string): void {
    this.manufacturerId = manufacturerId;
    this.recipeAssignments = this.bomCatalogs
      .filter(c => c.manufacturer.id === manufacturerId)
      .map(c => {
        const convertedModel: PreferenceCardAssignment = new PreferenceCardAssignment();

        convertedModel.catalog = c;
        convertedModel.quantity = 1;

        return convertedModel;
      });
  }
}
