<app-default-modal
  [title]="'shared.labels.comments' | language"
  [size]="'small'"
  [disableSubmitButton]="!comment"
  [showPrevStepButton]="data.type === 'EVENT'"
  [prevButtonTitle]="(isAdmin ? 'shared.labels.viewDetails' : 'events.list.viewEventInfo') | language"
  [prevButtonType]="'primary'"
  [buttonColor]="'success'"
  [buttonTitle]="permissionService.isGranted('common', 'addComment') ? ('shared.labels.save' | language) : null"
  (clickEmitter)="save()"
  (backEmitter)="isAdmin ? viewDetails() : viewEventInfo()"
>
  <div data-flex="100" data-layout="column" data-layout-gap="16px">
    <div class="staticProgressBarContainer">
      <mat-progress-bar *ngIf="loading$ | async" mode="query"></mat-progress-bar>
    </div>
    <div data-layout="row">
      <span *ngIf="data.additionalNote" class="caption" [innerHTML]="data.additionalNote"></span>
    </div>

    <div class="notesListContainer" [ngClass]="{ withNote: data.additionalNote }">
      <app-comments-list [notes]="notes" [allowEdit]="false"></app-comments-list>
    </div>

    <div *appHasPermission="{ scope: 'common', permissionName: 'addComment' }" data-layout="column" data-layout-gap="16px">
      <app-wysiwyg
        id="addCommentInput"
        data-flex="100"
        [modalView]="true"
        [maxLength]="1000"
        [hideMentions]="data?.hideMentions"
        [value]="comment"
        (valueChanged)="comment = $event"
      />
    </div>
  </div>
</app-default-modal>
