import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Optional, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FacilityService } from '@services/facility.service';
import { AlertsService } from '@services/internal/alerts.service';
import { CanAutoSaveDirective } from '@guards/autosave/can-auto-save';
import { CatalogModel, CatalogParLevelDTOModel, ContactModel, FacilityModel, LocationModel, ManufacturerModel } from '@shared/models';
import { CatalogService } from '@services/catalog.service';
import { FacilityParLevelRequestModel, ParLevelDTO } from '@shared/models/request-models';
import { PermissionService } from '@services/internal/permission.service';
import { BehaviorSubject, finalize, firstValueFrom, Observable, take } from 'rxjs';
import { MatStepper } from '@angular/material/stepper';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ScrollTo } from '@shared/utils/scroll-to';

@Component({
  selector: 'app-create-facilities',
  templateUrl: './create-facilities.component.html',
  styleUrls: ['./create-facilities.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class CreateFacilitiesComponent extends CanAutoSaveDirective {
  @ViewChild('stepper') stepperRef: MatStepper;

  facilityParLevel: CatalogParLevelDTOModel[] = [];
  formBillingContacts: UntypedFormGroup;
  formName: UntypedFormGroup;
  formPrimaryContacts: UntypedFormGroup;
  formIntegration: UntypedFormGroup;
  formsValid: boolean = false;
  formsTouched$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  additionalContacts: UntypedFormGroup[] = [];
  additionalContactsData: ContactModel[] = [];
  unCheckDefaultSalesOrderEmailsTrigger: number = 0;
  unCheckDefaultInvoicesEmailsTrigger: number = 0;

  constructor(
    public facilityService: FacilityService,
    public router: Router,
    public alertsService: AlertsService,
    public catalogService: CatalogService,
    public ref: ChangeDetectorRef,
    public permissionService: PermissionService,
    @Optional() public dialogRef: MatDialogRef<CreateFacilitiesComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public redirect: boolean
  ) {
    super();
  }

  static buildRequestParams(th: {
    formBillingContacts: UntypedFormGroup;
    formName: UntypedFormGroup;
    formPrimaryContacts: UntypedFormGroup;
    formIntegration: UntypedFormGroup;
    additionalContacts: UntypedFormGroup[];
  }): FacilityModel {
    const primaryContact = {
      primaryContact: {
        ...th.formPrimaryContacts.value,
        ...{
          isSalesOrderDefault: th.formPrimaryContacts.value.email && th.formPrimaryContacts.value.isSalesOrderDefault,
          isInvoiceDefault: th.formPrimaryContacts.value.email && th.formPrimaryContacts.value.isInvoiceDefault
        }
      }
    };
    const additionalPrimaryContacts = th.additionalContacts.map(form => ({
      ...form.value,
      ...{ additionalEmail: '', type: 'ADDITIONAL_PRIMARY' },
      ...{
        isSalesOrderDefault: form.value.email && form.value.isSalesOrderDefault,
        isInvoiceDefault: form.value.email && form.value.isInvoiceDefault
      }
    }));
    const billingContact = {
      billingContact: {
        email: th.formBillingContacts.value.email,
        additionalEmail: th.formBillingContacts.value.additionalEmail,
        name: th.formBillingContacts.value.name,
        phone: th.formBillingContacts.value.phone,
        id: th.formBillingContacts.value.id,
        phoneExtension: th.formBillingContacts.value.phoneExtension,
        isSalesOrderDefault: th.formBillingContacts.value.email && th.formBillingContacts.value.isSalesOrderDefault,
        isInvoiceDefault: th.formBillingContacts.value.email && th.formBillingContacts.value.isInvoiceDefault
      }
    };
    const result: FacilityModel = {
      ...th.formName?.value,
      ...primaryContact,
      ...{ additionalPrimaryContacts },
      ...billingContact
    };
    const facilityType = !!th.formName?.value.facilityType ? th.formName.value.facilityType : null;
    result.paymentTerm = th.formBillingContacts.value.paymentTerm ?? null;
    result.paymentStreetAddress = th.formBillingContacts.value.paymentStreetAddress;
    result.paymentZipCode = th.formBillingContacts.value.paymentZipCode;
    result.paymentAddressState = th.formBillingContacts.value.paymentAddressState;
    result.paymentCity = th.formBillingContacts.value.paymentCity;
    result.facilityType = facilityType;
    result.isCompletedContract = th.formBillingContacts.value.isCompletedContract;
    result.useAlternateCatalogs = th.formBillingContacts.value.useAlternateCatalogs;
    result.contractRenewalDate = th.formBillingContacts.value.contractRenewalDate;
    result.isCreditHold = th.formBillingContacts.value.isCreditHold;
    result.paymentCountry = th.formBillingContacts.value.paymentCountry;
    result.paymentOptionalAddress = th.formBillingContacts.value.paymentOptionalAddress;
    result.paymentGooglePlaceId = th.formBillingContacts.value.paymentGooglePlaceId;

    result.customerId = th.formIntegration?.value.customerId;
    result.xeroCustomerId = th.formIntegration?.value.xeroCustomerId;

    return result;
  }

  addNewParLevel(): void {
    if (!this.checkParLevelValidation(true)) {
      return;
    }
    const catalog = new CatalogModel();
    const manufacturer = new ManufacturerModel();
    const location = new LocationModel();
    this.facilityParLevel.push({
      catalog,
      parLevel: 1,
      disabled: false,
      manufacturer,
      location
    });
    this.ref.markForCheck();

    // Autoscroll to the new par level item
    setTimeout(() => {
      const nodes = document.querySelectorAll('.parLevelItemContainer');
      nodes[nodes.length - 1].scrollIntoView({
        behavior: 'smooth'
      });
    });
  }

  autoSave(): void {
    this.submit(false);
  }

  autoSaveFormsCheckValid(): UntypedFormGroup[] {
    const forms: UntypedFormGroup[] = [this.formName, this.formPrimaryContacts, this.formBillingContacts, this.formIntegration];

    if (this.additionalContacts.length) {
      this.additionalContacts.forEach(f => forms.push(f));
    }

    return forms;
  }

  checkValidation(): void {
    setTimeout(() => {
      const additionalContactsValid: boolean = this.additionalContacts.reduce((accumulator: boolean, currentValue: UntypedFormGroup) => {
        if (!currentValue.valid) {
          return false;
        }
        return accumulator;
      }, true);
      const additionalContactsTouched: boolean = this.additionalContacts.some((currentValue: UntypedFormGroup) => currentValue.touched);
      this.formsValid =
        this.formName?.valid &&
        this.formPrimaryContacts?.valid &&
        this.formBillingContacts?.valid &&
        (this.permissionService.isGranted('directory', 'canReadCustomerIdAtFacilityPage') ? this.formIntegration?.valid : true) &&
        additionalContactsValid;
      this.formsTouched$.next(
        this.formName?.touched ||
          this.formPrimaryContacts?.touched ||
          this.formBillingContacts?.touched ||
          additionalContactsTouched ||
          (this.permissionService.isGranted('directory', 'canReadCustomerIdAtFacilityPage') ? this.formIntegration?.touched : false)
      );
      this.ref.markForCheck();
    }, 50);
  }

  checkParLevelValidation(showAlert: boolean = false): boolean {
    const parLevelInvalid = this.facilityParLevel.some(parLevel => !parLevel.catalog?.id);
    if (parLevelInvalid) {
      if (showAlert) {
        this.alertsService.showError('shared.alerts.errorMessages.invalidParLevelItem', null, 5000);
      }
      return false;
    }
    return true;
  }

  deleteParLevel(index: number): void {
    this.facilityParLevel.splice(index, 1);
    this.ref.markForCheck();
  }

  editParLevel(index: number): void {
    this.facilityParLevel[index].disabled = false;
    this.facilityParLevel = [...[], ...this.facilityParLevel];
    this.ref.markForCheck();
  }

  markAsTouched(): void {
    this.formPrimaryContacts.markAllAsTouched();
    this.formPrimaryContacts.markAsDirty();
    this.checkValidation();
  }

  saveParLevel(id: string, isRedirectToDetailPage: boolean): void {
    const parLevelDTO: ParLevelDTO[] = this.facilityParLevel.map((item: CatalogParLevelDTOModel) => {
      if (!item.catalog.id) {
        return null;
      }
      return {
        catalogId: item.catalog.id,
        parLevel: +item.parLevel,
        locationId: item.location?.id
      };
    });

    if ((parLevelDTO.indexOf(null) !== -1 || !parLevelDTO.length) && this.redirect) {
      this.router.navigate([`/directory/facilities/edit/${id}`]).then();
      return;
    }

    const queryParams: FacilityParLevelRequestModel = {
      catalogParLevelRequestDTOS: parLevelDTO
    };

    this.facilityService
      .postFacilityParLevel(id, queryParams)
      .pipe(take(1))
      .subscribe(() => {
        if (isRedirectToDetailPage) {
          this.router.navigate([`/directory/facilities/edit/${id}`]).then();
        }
      });
  }

  selectLocation(event: { location: LocationModel; index: number }): void {
    const { location, index } = event;
    this.facilityParLevel[index].location = location;
    this.facilityParLevel = [...[], ...this.facilityParLevel];
    this.ref.markForCheck();
  }

  selectCatalog(event: { catalog: CatalogModel; index: number }): void {
    const { catalog, index } = event;
    this.facilityParLevel[index].catalog = catalog;
    this.facilityParLevel = [...[], ...this.facilityParLevel];
    this.ref.markForCheck();
  }

  selectManufacturer(event: { index: number; manufacturer: ManufacturerModel }): void {
    const { manufacturer, index } = event;
    this.facilityParLevel[index].manufacturer = manufacturer;
    this.facilityParLevel[index].catalog = new CatalogModel();
    this.facilityParLevel[index].location = new LocationModel();
    this.facilityParLevel = [...[], ...this.facilityParLevel];
    this.ref.markForCheck();
  }

  submit(isRedirectToDetailPage: boolean = true, saveParLevel: boolean = false): Observable<string> {
    this.loading$.next(true);

    return new Observable(observer => {
      const queryParams = CreateFacilitiesComponent.buildRequestParams(this);

      this.facilityService
        .createFacility(queryParams)
        .pipe(
          take(1),
          finalize(() => this.loading$.next(false))
        )
        .subscribe((id: string) => {
          if (isRedirectToDetailPage || saveParLevel) {
            this.saveParLevel(id, isRedirectToDetailPage);
          }

          if (id && this.permissionService.isGranted('directory', 'markFacilityContract')) {
            this.facilityService.putFacilityContract(id, this.formBillingContacts.value.isCompletedContract).pipe(take(1)).subscribe();
          }

          this.formName.reset();
          this.formPrimaryContacts.reset();
          this.formBillingContacts.reset();

          if (this.permissionService.isGranted('directory', 'canReadCustomerIdAtFacilityPage')) {
            this.formIntegration.reset();
          }

          observer.next(id);
        });
    });
  }

  submitWithSubscription(): void {
    this.submit().pipe(take(1)).subscribe();
  }

  submitWithoutRedirect(): void {
    this.submit(false, true)
      .pipe(take(1))
      .subscribe((id: string) => this.dialogRef.close(id));
  }

  addFacilityContact(contact?: ContactModel): void {
    this.additionalContactsData.push(contact ?? new ContactModel());

    if (contact) {
      ScrollTo('#addNewContactButton', 550);
    }
  }

  async deleteAdditionalContacts(index: number, facilityContactId: string = null): Promise<void> {
    if (facilityContactId) {
      this.loading$.next(true);
      const allowDelete: boolean = await firstValueFrom(this.facilityService.deleteFacilityContact(facilityContactId));
      this.loading$.next(false);
      if (!allowDelete) {
        return;
      }
    }
    this.additionalContactsData.splice(index, 1);
    this.additionalContacts.splice(index, 1);
    this.additionalContactsData = [...[], ...this.additionalContactsData];
    this.ref.markForCheck();
  }
}
