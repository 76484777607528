import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { InventoriesService } from '@services/inventories.service';
import { InventoryModel, UserModel } from '@shared/models';
import { BehaviorSubject, finalize, firstValueFrom, take } from 'rxjs';
import { DisplayName } from '@shared/utils/form-elements/display-with-autocomplete';
import { TrackById } from '@shared/utils/form-elements/track-by';
import { IFilter } from '@shared/interfaces';
import { InventoryPageableParams } from '@shared/models/build-models';
import { FilterUniqueArray } from '@shared/utils/arrays/filter-unique-array';
import { TIcons } from '@shared/type/icons.type';
import { LanguageService } from '@services/internal/language.service';
import { TranslationsKeys } from '@shared/type/i18n.type';
import { PermissionService } from '@services/internal/permission.service';
import { IAvailableContainerParams } from '@shared/interfaces/inventory/available-container';
import { UserSubordinationService } from '@services/user-subordination.service';
import { DefaultAutoCompleteSize, MaxContainerChildrenCount } from '@constants';

@Component({
  selector: 'app-container-autocomplete',
  templateUrl: './container-autocomplete.component.html',
  styleUrls: ['./container-autocomplete.component.scss'],
  standalone: false
})
export class ContainerAutocompleteComponent {
  @Input() container: InventoryModel = new InventoryModel();
  @Input() label: string = LanguageService.instant('shared.labels.container');
  @Input() filterView: boolean = false;
  @Input() multiple: boolean = false;
  @Input() selectedChips: InventoryModel[] = [];
  @Input() removable: boolean = true;
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() showBarcodeIcon: boolean = false;
  @Input() showClearIcon: boolean = false;
  @Input() tooltip: TranslationsKeys;
  @Input() searchWithoutBOM: boolean = false;
  @Input() showLinkToDetail: boolean = true;
  @Input() availableContainersOnly: boolean = false;
  @Input() displayReferenceNumber: boolean = false;
  @Input() custody: UserModel | 'ORGANIZATION' = null;
  @Input() invalid: boolean = false;
  @Input() notInPendingTransfer: boolean = true;
  @Input() includeDistributorCustody: boolean = false;
  @Input() searchQuickTransferContainers: boolean = false;
  // If a search is triggered by a value scanned via a scanner
  @Input() set forceSearchValue(data: string) {
    if (data) {
      const mockContainer: InventoryModel = new InventoryModel();
      mockContainer.name = data;
      this.container = mockContainer;
      this.autofocus = true;
      setTimeout(() => (this.autofocus = false));
    }
  }

  @Output() selectedOptionEmitter = new EventEmitter<InventoryModel>();
  @Output() removeChipEmitter = new EventEmitter<string>();
  @Output() clearInputEmitter = new EventEmitter<void>();
  @Output() valueChangedEmitter = new EventEmitter<string>();

  permissionService = inject(PermissionService);
  private inventoriesService = inject(InventoriesService);
  private userSubordinationService = inject(UserSubordinationService);

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  initialLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  searchedData: InventoryModel[] = [];
  autofocus: boolean = false;
  forceBlurEvent: boolean = false;

  readonly displayFn = DisplayName;
  readonly trackBy = TrackById;

  async searchContainer(value: string, skipSelectSingleOption: boolean = false, page: number = 0): Promise<void> {
    this.initialLoading$.next(false);
    this.forceBlurEvent = false;

    if (this.loading$.value) {
      return;
    }

    this.loading$.next(true);
    this.searchedData = [];

    const params: IFilter = {
      size: DefaultAutoCompleteSize,
      search: value,
      state: 'ACTIVE',
      itemType: 'CONTAINER',
      notInPendingTransfer: this.notInPendingTransfer,
      page,
      sort: 'name,asc'
    };

    if (this.availableContainersOnly) {
      this.getAvailableContainers(value, skipSelectSingleOption);
      return;
    }

    if (this.custody) {
      if (this.custody === 'ORGANIZATION' || !this.custody.id) {
        params.inOrgCustody = true;
      } else {
        params.custodyId = this.custody.id;
      }
    }

    if (this.includeDistributorCustody) {
      const userId: string = (this.custody as UserModel).id;
      const subordinations = await firstValueFrom(this.userSubordinationService.getSubordinations([userId]));
      const subordinationsIds = subordinations[userId]?.filter(s => s.user.showInventoriesToSales).map(s => s.user.id) || [];

      if (subordinationsIds.length) {
        params.custodyId = null;
        params.custodyIds = [userId, ...subordinationsIds];
      } else {
        params.custodyId = userId;
      }
    }

    if (this.searchWithoutBOM) {
      params.searchWithoutBom = true;
    }

    const queryParams: InventoryPageableParams = new InventoryPageableParams(params);
    const serviceCall = this.searchQuickTransferContainers
      ? this.inventoriesService.getPageableQuickTransfer(queryParams)
      : this.inventoriesService.getPageable(queryParams);

    serviceCall
      .pipe(
        take(1),
        finalize(() => this.loading$.next(false))
      )
      .subscribe(data => {
        if (this.displayReferenceNumber) {
          data.content.forEach(c => (c.catalogNumber = c.catalog.referenceNumber));
        }

        this.searchedData = FilterUniqueArray(data.content, this.selectedChips);
        this.selectSingleOption(value, skipSelectSingleOption);
        //CC-5432 Load next page if all items selected
        if (this.searchedData?.length === 0 && data?.totalElements > this.selectedChips?.length && page < data.totalPages) {
          setTimeout(() => this.searchContainer(value, skipSelectSingleOption, page + 1));
        }
      });
  }

  doIconAction(icon: TIcons): void {
    if (icon === 'close') {
      this.clearInputEmitter.emit();
      this.searchContainer('', true);
    }
  }

  selectOption(container: InventoryModel): void {
    this.selectedOptionEmitter.emit(container);
    if (this.multiple) {
      //Hide selected item from search results
      this.searchedData = this.searchedData.filter(c => c.id !== container.id);
    }
  }

  removeContainer(container: InventoryModel): void {
    this.removeChipEmitter.emit(container.id);
  }

  private getAvailableContainers(value: string, skipSelectSingleOption: boolean = false): void {
    const params: IAvailableContainerParams = {
      inventoryCount: MaxContainerChildrenCount
    };

    if (this.custody === 'ORGANIZATION' || !this.custody?.id) {
      params.inOrgCustody = true;
    } else {
      params.custodyId = this.custody.id;
    }

    if (value) {
      params.containerName = value;
    }
    params.limit = 20;

    this.inventoriesService
      .getAvailableContainers(params)
      .pipe(
        take(1),
        finalize(() => this.loading$.next(false))
      )
      .subscribe(data => {
        // Converting AvailableContainerModel to InventoryModel
        const convertedData: InventoryModel[] = data.map(c => {
          const container: InventoryModel = new InventoryModel();
          container.name = c.containerName;
          container.id = c.containerId;
          container.childrenCount = c.quantity;
          return container;
        });
        this.searchedData = [...convertedData];
        this.selectSingleOption(value, skipSelectSingleOption);
      });
  }

  private selectSingleOption(value: string, skipSelectSingleOption: boolean = false): void {
    if (skipSelectSingleOption && this.searchedData.length === 1) {
      this.forceBlurEvent = true;
      return;
    }

    if (!value && this.searchedData.length === 1 && this.required) {
      this.selectOption(this.searchedData[0]);
      this.forceBlurEvent = true;
    } else {
      this.forceBlurEvent = false;
    }
  }
}
