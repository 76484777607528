import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DestroySubscriptions } from '@shared/classes/destroy-subscriptions';
import { FacilityModel, ProcedureModel, ProductLineModel } from '@shared/models';
import { MatAutocompleteRequireMath } from '@shared/validators/mat-autocomplete-require-math';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CreateSmallEntityConfig } from '@constants';
import { CreateProductComponent } from 'src/app/features/inventory/products/partials/modals/create-product-line/create-product.component';
import { firstValueFrom, takeUntil } from 'rxjs';
import { ProductsService } from '@services/products.service';
import { PermissionService } from '@services/internal/permission.service';

@Component({
  selector: 'app-form-assignments',
  templateUrl: './form-assignments.component.html',
  styleUrls: ['./form-assignments.component.scss'],
  standalone: false
})
export class FormAssignmentsComponent extends DestroySubscriptions implements OnInit {
  @Input() removable: boolean = false;
  @Input() createEntityFlow: boolean = false;
  @Input() selectedFacilities: FacilityModel[] = [];
  @Input() selectedProcedures: ProcedureModel[] = [];
  @Input() selectedProducts: ProductLineModel[] = [];
  @Input() searching: boolean = false;

  @Output() removeItemEmitter = new EventEmitter();
  @Output() formNameValueChangeEmitter = new EventEmitter<UntypedFormGroup>();
  @Output() selectItemEmitter = new EventEmitter();

  permissionService = inject(PermissionService);
  private formBuilder = inject(UntypedFormBuilder);
  private dialog = inject(MatDialog);
  private productsService = inject(ProductsService);

  formAssignments: UntypedFormGroup;

  ngOnInit(): void {
    this.createForm();
    this.formChanges();
  }

  async createProduct(): Promise<void> {
    const dialogConfig: MatDialogConfig = { ...CreateSmallEntityConfig };
    dialogConfig.data = {
      redirect: false
    };
    const dialogRef = this.dialog.open(CreateProductComponent, dialogConfig);
    const params = await firstValueFrom(dialogRef.afterClosed());

    if (params?.productLineId) {
      const res: ProductLineModel = await firstValueFrom(this.productsService.getProduct(params.productLineId));
      if (res) {
        this.selectItem('PRODUCT_LINE', res);
      }
    }
  }

  removeItem(type: 'PROCEDURE' | 'FACILITY' | 'PRODUCT_LINE', id: string): void {
    this.removeItemEmitter.emit({ type, id });
    this.formAssignments.markAsTouched();
    this.formAssignments.markAsDirty();
    this.formNameValueChangeEmitter.emit(this.formAssignments);
  }

  selectItem(type: 'PROCEDURE' | 'FACILITY' | 'PRODUCT_LINE', value: ProcedureModel | FacilityModel | ProductLineModel): void {
    this.selectItemEmitter.emit({ type, value });
    this.formAssignments.markAsTouched();
    this.formAssignments.markAsDirty();
    this.formNameValueChangeEmitter.emit(this.formAssignments);
  }

  private createForm(): void {
    this.formAssignments = this.formBuilder.group({
      facilities: ['', [MatAutocompleteRequireMath]],
      procedures: ['', [MatAutocompleteRequireMath]],
      products: ['', [MatAutocompleteRequireMath]]
    });
  }

  private formChanges(): void {
    setTimeout(() => {
      this.formNameValueChangeEmitter.emit(this.formAssignments);
    });
    this.formAssignments.valueChanges.pipe(takeUntil(this.subscriptions)).subscribe(() => {
      this.formNameValueChangeEmitter.emit(this.formAssignments);
    });
  }
}
