import { Component, Input, Output, EventEmitter, inject } from '@angular/core';
import { BillOfMaterialsService } from '@services/bill-of-materials.service';
import { LanguageService } from '@services/internal/language.service';
import { PermissionService } from '@services/internal/permission.service';
import { IFilter } from '@shared/interfaces';
import { BillOfMaterials, UserModel } from '@shared/models';
import { BillOfMaterialsPageableParams } from '@shared/models/build-models';
import { TranslationsKeys } from '@shared/type/i18n.type';
import { TIcons } from '@shared/type/icons.type';
import { DisplayName } from '@shared/utils/form-elements/display-with-autocomplete';
import { BehaviorSubject, finalize, take } from 'rxjs';
import { EBOMType } from '@shared/enum';
import { DefaultAutoCompleteSize } from '@constants';

@Component({
  selector: 'app-bom-autocomplete',
  templateUrl: './bom-autocomplete.component.html',
  styleUrls: ['./bom-autocomplete.component.scss'],
  standalone: false
})
export class BomAutocompleteComponent {
  @Input() bom: BillOfMaterials = new BillOfMaterials();
  @Input() label: string = LanguageService.instant('inventories.bomLabel');
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() filterView: boolean = false;
  @Input() showAddNewButton: boolean = false;
  @Input() showViewDetailsButton: boolean = false;
  @Input() showClearIcon: boolean = false;
  @Input() tooltip: TranslationsKeys;
  @Input() invalid: boolean = false;
  @Input() user: UserModel = new UserModel();
  @Input() bomType: keyof typeof EBOMType;

  @Output() optionSelectedEmitter = new EventEmitter<BillOfMaterials>();
  @Output() createBOMEmitter = new EventEmitter<void>();
  @Output() clearInputEmitter = new EventEmitter<void>();
  @Output() valueChangedEmitter = new EventEmitter<string>();

  permissionService = inject(PermissionService);
  private billOfMaterialsService = inject(BillOfMaterialsService);

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  initialLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  searchedData: BillOfMaterials[] = [];
  forceBlurEvent: boolean = false;

  readonly displayFn = DisplayName;

  searchBOM(value: string, skipSelectSingleOption: boolean = false): void {
    this.initialLoading$.next(false);
    this.forceBlurEvent = false;

    if (this.loading$.value) {
      return;
    }

    this.loading$.next(true);
    this.searchedData = [];

    const params: IFilter = {
      size: DefaultAutoCompleteSize,
      search: value,
      state: 'ACTIVE',
      sort: 'name,asc'
    };
    if (this.bomType) {
      params.bomType = this.bomType;
    }

    if (this.permissionService.isRole('SALES') || this.permissionService.isRole('DISTRIBUTOR')) {
      params.userId = this.user.id;
    }

    const queryParams: BillOfMaterialsPageableParams = new BillOfMaterialsPageableParams(params);

    this.billOfMaterialsService
      .getPageable(queryParams)
      .pipe(
        take(1),
        finalize(() => this.loading$.next(false))
      )
      .subscribe(data => {
        this.searchedData = data.content;

        if (skipSelectSingleOption && this.searchedData.length === 1) {
          this.forceBlurEvent = true;
          return;
        }

        if (!value && this.searchedData.length === 1 && this.required) {
          this.selectBOM(this.searchedData[0]);
          this.forceBlurEvent = true;
        } else {
          this.forceBlurEvent = false;
        }
      });
  }

  doIconAction(icon: TIcons): void {
    if (icon === 'close') {
      this.clearInputEmitter.emit();
      this.searchBOM('', true);
    }
  }

  selectBOM(bom: BillOfMaterials): void {
    this.optionSelectedEmitter.emit(bom);
  }

  async createBOM(): Promise<void> {
    await import('../../../../../features/inventory/bill-of-materials/bill-of-materials.module').then(m => m.BillOfMaterialsModule);
    this.createBOMEmitter.emit();
  }
}
