<div class="full-width" data-layout="row" data-layout-align="start center">
  <div class="full-width">
    <app-input-with-chips-kit
      [dataCy]="'searchTagsInput'"
      [chipDataCy]="'searchTagsChip'"
      [chipRemoveData_cy]="'searchTagsRemoveChipButton'"
      [selectedChips]="selectedChips"
      [label]="label | language"
      [showAddChipButton]="true"
      [disabled]="disabled"
      [selectable]="true"
      [icons]="icons"
      [debounceEvents]="['keyup', 'focus']"
      [debounceTime]="1000"
      [required]="false"
      [requiredView]="false"
      [removable]="removable"
      [addOnBlur]="true"
      [clearInput]="clearInput"
      [autocompleteName]="autoCompleteTags"
      [parseChipByProperty]="'tagText'"
      [separatorKeysCodes]="separatorKeysCodes"
      [tooltipText]="tooltipText"
      (chipsRemovedEmitter)="removeChip($event)"
      (debounceTimeEndedEmitter)="searchTags($event)"
      (chipInputEndEmitter)="selectTag($event?.input?.value, true); clearInput = true"
      (inputCleared)="clearInput = false"
    ></app-input-with-chips-kit>

    <mat-autocomplete #autoCompleteTags="matAutocomplete" (optionSelected)="selectTag($event.option?.value?.tagText); clearInput = true">
      <mat-option *ngIf="loading$ | async" class="is-loading" disabled>
        <mat-progress-spinner value="30" class="spinner" mode="indeterminate" diameter="30"></mat-progress-spinner>
      </mat-option>
      <ng-container *ngIf="(loading$ | async) === false">
        <mat-option *ngIf="(searchedData$ | async)?.length === 0; else data" disabled class="connectSxAutocompleteOption"
          ><span>{{ 'shared.labels.noItemsFound' | language }}</span></mat-option
        >
        <ng-template #data>
          <cdk-virtual-scroll-viewport
            itemSize="0"
            class="virtualAutocompleteScroll"
            [style.height.px]="(searchedData$ | async).length < 3 ? (searchedData$ | async).length * 50 : 150"
          >
            <mat-option
              data-cy="tagOption"
              *cdkVirtualFor="let tag of searchedData$ | async"
              class="connectSxAutocompleteOption"
              [value]="tag"
            >
              <span>{{ tag.tagText }}</span>
            </mat-option>
          </cdk-virtual-scroll-viewport>
        </ng-template>
      </ng-container>
    </mat-autocomplete>
  </div>
</div>
