@let event = event$ | async;

<form
  class="form-group-emphasized"
  data-layout="column"
  [formGroup]="assignments"
  [ngClass]="{ 'last-child nullMargin': createEntityFlow }"
  [attr.data-layout-gap]="createEntityFlow ? '24px' : '16px'"
  data-cy="eventStep2Form"
>
  <app-title
    [title]="
      selectedEventType === 'STOCK_ADJUSTMENT'
        ? ('events.detail.containersLabel' | language)
        : config.containers
          ? ('events.detail.manufacturersProductsContainersLabel' | language)
          : ('events.detail.manufacturersProductsLabel' | language)
    "
    [type]="'h5'"
  ></app-title>

  <div class="form-group-emphasized-container" data-layout="column" [attr.data-layout-gap]="createEntityFlow ? '16px' : '32px'">
    <app-manufacturer-autocomplete
      *ngIf="config.manufacturers"
      data-flex="100"
      [disabled]="assignments.get('manufacturerIds').disabled || !allowEdit"
      [invalid]="assignments.get('manufacturerIds').invalid"
      [user]="prevStepForm?.get('representativeId').value"
      [multiple]="true"
      [sortBy]="'name'"
      [required]="true"
      [removable]="createEntityFlow && !assignments.disabled"
      [selectedItems]="selectedManufacturers | async"
      [showLinkToDetail]="allowCreateAndInNewIconInAutocompletes"
      (removeChipEmitter)="removeManufacturerEmitter.emit($event)"
      (selectedOptionEmitter)="selectManufacturerEmitter.emit([$event]); assignments.get('products').enable()"
    ></app-manufacturer-autocomplete>

    <app-preference-cards-autocomplete
      *ngIf="config.preferenceCard"
      [recipe]="assignments.get('preferenceCard').value"
      [label]="'shared.components.optionalRecipe' | language"
      [showClearButton]="true"
      [procedure]="event.procedure || prevStepForm?.get('procedureId')?.value"
      [physicians]="event.physicians || [prevStepForm?.get('physicianId').value]"
      [userId]="event.representative?.id || prevStepForm?.get('representativeId').value?.id"
      [disabled]="!allowEdit || assignments.disabled || !permissionService.isGranted('userLevel', 'manageInventoryMovementsAllowed')"
      [showViewInModalButton]="true"
      [showLinkButton]="true"
      (valueChangedEmitter)="assignments.get('preferenceCard').setValue($event)"
      (clearEmitter)="
        assignments.get('preferenceCard').setValue(null); assignments.get('preferenceCard').markAsTouched(); assignments.markAsDirty()
      "
      (selectedOptionEmitter)="
        assignments.get('preferenceCard').setValue($event); assignments.get('preferenceCard').markAsTouched(); assignments.markAsDirty()
      "
    ></app-preference-cards-autocomplete>

    <div *ngIf="config.productLine" data-flex="100" data-layout="column" data-layout-gap="8px">
      <app-product-autocomplete
        [custody]="filterByRepresentative ? prevStepForm?.get('representativeId')?.value : null"
        [multiple]="true"
        [disabled]="!(selectedManufacturers | async).length || !allowEdit || assignments.disabled"
        [showAddNewButton]="allowCreateAndInNewIconInAutocompletes"
        [removable]="createEntityFlow"
        [selectedChips]="selectedProducts"
        [showOnlyAssignedOption]="showFilterProducts"
        [userType]="'REPRESENTATIVE'"
        [manufacturers]="selectedManufacturers | async"
        [required]="selectedEventType === 'TRIAL' || selectedEventType === 'STOCKING_ORDER'"
        [showLinkToDetail]="allowCreateAndInNewIconInAutocompletes"
        (removeChipEmitter)="removeProductEmitter.emit($event); assignments.get('products').markAsTouched()"
        (optionSelectedEmitter)="selectProductEmitter.emit($event)"
        (addProductEmitter)="addProductLineEmitter.emit()"
      ></app-product-autocomplete>

      <ng-container
        *ngIf="
          config.facility &&
          selectedEventType !== 'DAMAGE' &&
          selectedEventType !== 'OTHER' &&
          selectedEventType !== 'SAMPLE' &&
          !createEntityFlow
        "
      >
        @if (event.readyForUse || (assignedDevices$ | async) === false) {
          <app-checkbox-kit
            class="subtitleContainer"
            [title]="'events.detail.readyForUse' | language"
            [dataCy]="'readyForUseCheckbox'"
            [disabled]="event.readyForUse || (isOffline$ | async) === true || event?.representative?.isOffline || !allowEdit"
            [initialValue]="event.readyForUse"
            [forceFalseValue]="true"
            (checkEmitter)="readyForUse()"
          ></app-checkbox-kit>
          <span *ngIf="event.makingReadyForSaleDateTime" class="readyForUseHint">
            {{
              ('inventories.modifiedBy' | language) +
                ' ' +
                event.makeReadyForSaleBy?.name +
                ' ' +
                ('inventories.modifiedOn' | language) +
                ' ' +
                (event.makingReadyForSaleDateTime | userDate)
            }}
          </span>
        }
      </ng-container>
    </div>

    <div data-layout="row" data-layout-lt-lg="column" data-layout-gap="16px">
      <app-container-autocomplete
        *ngIf="config.containers"
        data-flex="50"
        data-flex-lt-lg="100"
        [disabled]="!allowEdit || assignments.disabled"
        [removable]="!assignments.disabled"
        [custody]="selectedCustody || 'ORGANIZATION'"
        [includeDistributorCustody]="selectedCustody?.role === 'SALES'"
        [tooltip]="'shared.tooltips.eventContainers'"
        [multiple]="true"
        [selectedChips]="selectedContainers"
        [label]="'events.detail.associatedContainersLabel' | language"
        [showLinkToDetail]="allowCreateAndInNewIconInAutocompletes"
        (removeChipEmitter)="removeContainerEmitter.emit($event)"
        (selectedOptionEmitter)="assignments.markAsTouched(); selectContainerEmitter.emit($event)"
      ></app-container-autocomplete>

      <app-select-kit
        data-flex="50"
        data-flex-lt-lg="100"
        [selectData]="eLoanersUsed"
        [dataCy]="'loanersUsedSelect'"
        [optionDataCy]="'loanersUsedOption'"
        [label]="'events.detail.loanersUsed' | language"
        [initialValue]="assignments.get('loanersUsed').value"
        [disabled]="assignments.get('loanersUsed').disabled"
        [translateValue]="true"
        [parseKeyByProperty]="'value'"
        [parseValueByProperty]="'key'"
        (selectEmitter)="
          assignments.get('loanersUsed').setValue($event); assignments.get('loanersUsed').markAsTouched(); assignments.markAsDirty()
        "
      />
    </div>
  </div>
</form>
