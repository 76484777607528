export class LocationPlaceModel {
  city: string;
  country: string;
  latitude: string;
  locationName: string;
  locationState: string;
  longitude: string;
  placeId: string;
  postalCode: string;
  street: string;
}
