import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { MatMenu } from '@angular/material/menu';
import { GlobalStoreService } from '@services/internal/global-store.service';
import { LanguageService } from '@services/internal/language.service';
import { TranslationsKeys } from '@shared/type/i18n.type';
import { TInputType, TDebounceEvents, TIcons } from '@shared/type/index.type';
import { OpenLinkInBrowser } from '@shared/utils/cross-platform/open-link-in-browser';
import { BehaviorSubject } from 'rxjs';
import { TTooltipPosition } from '@shared/type/tooltip-position.type';

@Component({
  selector: 'app-input-kit',
  templateUrl: './input-kit.component.html',
  styleUrls: ['./input-kit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputKitComponent),
      multi: true
    }
  ],
  standalone: false
})
export class InputKitComponent implements ControlValueAccessor {
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  @Input() icons: TIcons[] = [];
  @Input() placeholder: string = '';
  @Input() label: string = 'Label';
  @Input() keepFocus: boolean = false;
  @Input() set autofocus(data: boolean) {
    if (data) {
      setTimeout(() => this.input?.nativeElement.focus());
    }
  }
  @Input() set forceBlurEvent(data: boolean) {
    if (data) {
      setTimeout(() => this.autocomplete.closePanel());
    }
  }
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() dataCy: string = '';
  @Input() hint: string = '';
  @Input() error: string = '';
  @Input() borderTransparent: boolean = false;
  @Input() filterView: boolean = false;
  @Input() type: TInputType = 'text';
  @Input() pattern: string | RegExp = null;
  @Input() skeleton: boolean = false;
  @Input() set initialValue(data: any) {
    if (data || data === null || data === '') {
      this.updateValue(data);
    }
  }
  @Input() set observeAutofill(data: boolean) {
    if (data && GlobalStoreService.isWebChromeBrowser) {
      setTimeout(() => {
        if (this.input?.nativeElement?.matches(':-internal-autofill-selected') && !this.input?.nativeElement?.value) {
          this.browserAutofillVisible$.next(true);
          if (this.input.nativeElement.type === 'email') {
            this.browserAutofillHint$.next(LanguageService.instant('shared.hints.browserAutofill'));
          }
        }
      }, 700);
    }
  }
  @Input() debounceEvents: TDebounceEvents[] = [];
  @Input() debounceTime: number = 0;
  @Input() autocompleteName: MatAutocomplete = null;
  @Input() matMenuName: MatMenu = null;
  @Input() tooltipText: TranslationsKeys;
  @Input() size: 'medium' | 'small' = 'medium';
  @Input() readonly: boolean = false;
  @Input() invalidInput: boolean = false;
  @Input() searchInput: boolean = false;
  @Input() background: boolean = false;
  @Input() maxlength: number = 255;
  @Input() linkDetailPage: string;
  @Input() disableIcon: boolean = false;
  @Input() set searchValue(data: string) {
    if (!data) {
      return;
    }
    this.input.nativeElement.value = data;
    this.input.nativeElement.focus();
  }
  @Input() deprecated: boolean = false;
  @Input() autoScrollOnFocus: boolean = false;
  @Input() showInvalidStateOnInit: boolean = false;
  @Input() tooltipPosition: TTooltipPosition = 'left';

  @Output() iconClickEmitter = new EventEmitter<TIcons>();
  @Output() debounceTimeEndedEmitter = new EventEmitter<string>();
  @Output() valueChangedEmitter = new EventEmitter<any>();
  @Output() inputChangedEmitter = new EventEmitter<Event>();
  @Output() blurEmitter = new EventEmitter<void>();
  @Output() focusEmitter = new EventEmitter<void>();
  @Output() inputTouched = new EventEmitter<boolean>();
  @Output() enterEmitter = new EventEmitter<string>();

  focused: boolean = false;
  value: string = '';
  browserAutofillVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  browserAutofillHint$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  readonly isWebPlatform: boolean = GlobalStoreService.getPlatform() === 'web';
  readonly openLinkInBrowser = OpenLinkInBrowser;

  onChange: any = () => {};
  onTouch: any = () => {};

  scrollTo(element: HTMLInputElement): void {
    if (!this.autoScrollOnFocus) {
      return;
    }

    element?.scrollIntoView({ behavior: 'smooth' });
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  checkValueBeforeEmit(value: string): void {
    if (!this.placeholder) {
      this.debounceTimeEndedEmitter.emit(value);
      return;
    }

    if (value.length >= 2) {
      this.debounceTimeEndedEmitter.emit(value);
    }
  }

  updateValue(value: any) {
    if (value !== undefined && this.value !== value) {
      if (typeof value === 'string') {
        value = value.trim();
      }
      this.valueChangedEmitter.emit(value);
      this.browserAutofillHint$.next('');
      this.browserAutofillVisible$.next(false);
      this.value = value;
      this.onChange(value);
      this.onTouch();
    }
  }

  clearAutocomplete(): void {
    if (this.autocompleteName) {
      this.autocomplete.openPanel();
    }
    this.iconClickEmitter.emit('close');
  }
}
