import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ManufacturerModel } from '@shared/models';
import { TrackById } from '@shared/utils/form-elements/track-by';
import { BehaviorSubject, take } from 'rxjs';
import { DestroySubscriptions } from '@shared/classes/destroy-subscriptions';
import { UsersService } from '@services/users.service';
import { ManufacturerService } from '@services/manufacturer.service';
import { TranslationsKeys } from '@shared/type/i18n.type';

@Component({
  selector: 'app-choose-manufacturer-modal',
  templateUrl: './choose-manufacturer-modal.component.html',
  styleUrls: ['./choose-manufacturer-modal.component.scss'],
  standalone: false
})
export class ChooseManufacturerModalComponent extends DestroySubscriptions implements OnInit {
  manufacturerId: string = '';
  selectAll: boolean = false;
  manufacturersToShow$: BehaviorSubject<ManufacturerModel[]> = new BehaviorSubject<ManufacturerModel[]>([]);
  billedManufacturers$: BehaviorSubject<ManufacturerModel[]> = new BehaviorSubject<ManufacturerModel[]>([]);
  readonly company: ManufacturerModel = UsersService.getUser().organization.company;
  readonly trackBy = TrackById;

  constructor(
    private dialogRef: MatDialogRef<ChooseManufacturerModalComponent>,
    private manufacturerService: ManufacturerService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      manufacturers: ManufacturerModel[];
      allowSelectAll: boolean;
      allowUsingBilledManufacturers: boolean;
      type: 'EVENT' | 'BOM';
      title?: TranslationsKeys;
    }
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.data?.allowUsingBilledManufacturers) {
      const userManufacturers = this.data?.manufacturers?.filter(m => m.usedInEvent || m.billAsOrg);
      this.manufacturersToShow$.next(userManufacturers);
      return;
    }

    this.manufacturerService
      .getBilledManufacturers()
      .pipe(take(1))
      .subscribe(billedManufacturers => {
        this.billedManufacturers$.next(billedManufacturers);
        if (!billedManufacturers?.length) {
          return;
        }
        this.filterBilledOrgManufacturers(billedManufacturers);
        if (this.manufacturersToShow$.value.length === 1) {
          const billedManufacturerIds = billedManufacturers.map(m => m.id);
          this.close(billedManufacturerIds);
        }
      });
  }

  close(manufacturerIds: string[] = null): void {
    this.dialogRef.close(manufacturerIds);
  }

  submit(): void {
    if (this.selectAll) {
      const manufacturerIds: string[] = this.data.manufacturers.map(manufacturer => manufacturer.id);
      this.close(manufacturerIds);
    } else {
      if (this.manufacturerId === this.company.id && !this.data.allowUsingBilledManufacturers) {
        const billedManufacturerIds = this.billedManufacturers$.value.map(m => m.id);
        this.close(billedManufacturerIds);
        return;
      }
      this.close([this.manufacturerId]);
    }
  }

  private filterBilledOrgManufacturers(billedManufacturers: ManufacturerModel[] = []): void {
    /** In Org must be at least 1 manufacturer. Prevent null value when data is loading */
    if (!billedManufacturers?.length) {
      return;
    }
    const isExistBilledManufacturer = this.data.manufacturers.some(m => (billedManufacturers || []).findIndex(b => b.id === m.id));
    let filteredManufacturers: ManufacturerModel[] = [];
    if (isExistBilledManufacturer) {
      filteredManufacturers = this.data.manufacturers.filter(m => billedManufacturers.findIndex(b => m.id === b.id) === -1);
      filteredManufacturers.unshift(this.company);
    } else {
      filteredManufacturers = this.data.manufacturers;
    }
    const userManufacturers = filteredManufacturers.filter(m => m.usedInEvent || m.billAsOrg);
    this.manufacturersToShow$.next(userManufacturers);
  }
}
