<form
  class="form-group-emphasized"
  data-layout="column"
  [ngClass]="{ 'last-child nullMargin': createEntityFlow }"
  [attr.data-layout-gap]="createEntityFlow ? '24px' : '16px'"
  [formGroup]="formAssignments"
>
  <div data-layout="column" class="form-group-emphasized-wrap">
    <app-title [title]="'physicians.assignmentsLabel' | language" [type]="'h5'"></app-title>
  </div>

  <div class="form-group-emphasized-container" data-layout="row" data-layout-gap="16px" data-layout-lt-lg="column">
    <app-facility-autocomplete
      data-flex="32"
      data-flex-lt-lg="100"
      [multiple]="true"
      [disabled]="formAssignments.get('facilities').disabled"
      [invalid]="formAssignments.get('facilities').invalid"
      [removable]="removable"
      [showAddNewButton]="true"
      [selectedItems]="selectedFacilities"
      (selectedOptionEmitter)="selectItem('FACILITY', $event)"
      (removeChipEmitter)="removeItem('FACILITY', $event)"
    ></app-facility-autocomplete>
    <app-procedure-autocomplete
      data-flex="32"
      data-flex-lt-lg="100"
      [multiple]="true"
      [disabled]="formAssignments.get('procedures').disabled"
      [invalid]="formAssignments.get('procedures').invalid"
      [showAddNewButton]="true"
      [removable]="removable"
      [selectedItems]="selectedProcedures"
      (selectedOptionEmitter)="selectItem('PROCEDURE', $event)"
      (removeChipEmitter)="removeItem('PROCEDURE', $event)"
    ></app-procedure-autocomplete>
    <app-product-autocomplete
      data-flex="32"
      data-flex-lt-lg="100"
      [selectedChips]="selectedProducts"
      [multiple]="true"
      [showAddNewButton]="true"
      [disabled]="formAssignments.disabled"
      [removable]="!formAssignments.disabled"
      [showOnlyAssignedOption]="permissionService.isRole('SALES')"
      (optionSelectedEmitter)="selectItem('PRODUCT_LINE', $event)"
      (removeChipEmitter)="removeItem('PRODUCT_LINE', $event)"
      (addProductEmitter)="createProduct()"
    ></app-product-autocomplete>
  </div>
</form>
