<div
  [attr.data-cy]="dataCy"
  class="expandedSectionContainer"
  data-layout-gap="8px"
  [ngClass]="{ expandedSectionItem: expanded }"
  (click)="expandEmitter.emit(!expanded)"
  data-layout="row"
  data-layout-align="space-between center"
>
  <div data-layout="row" data-layout-align="start center" data-layout-gap="16px">
    <div *ngIf="icon" data-hide-xs class="expandedSectionIconContainer" data-layout-align="center center">
      <app-icon [type]="'outline'" [icon]="icon" [size]="24"></app-icon>
    </div>

    <div data-layout="column" data-layout-gap="2px">
      <span class="expandedSectionTitle">{{ title }}</span>
      <span *ngIf="description" class="caption">{{ description }}</span>
    </div>
  </div>

  <div data-layout="row" data-layout-align="end center" data-layout-gap="8px">
    <span *ngIf="chipText2" [ngClass]="chipClass2 + ' large'">{{ chipText2 }}</span>
    <span *ngIf="chipText" [ngClass]="chipClass + ' large'">{{ chipText }}</span>

    <app-icon [type]="'outline'" [icon]="expanded ? 'arrow_up_filled' : 'arrow_down_filled'" [size]="24"></app-icon>
  </div>
</div>
