import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { TIcons } from '@shared/type/icons.type';

@Component({
  selector: 'app-expanded-section',
  templateUrl: './expanded-section.component.html',
  styleUrls: ['./expanded-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class ExpandedSectionComponent {
  @Input() expanded: boolean = false;
  @Input() chipText: string = '';
  @Input() chipText2: string = '';
  @Input() chipClass: 'emphasizedOrange' | 'emphasizedGray' | 'emphasizedBlue' | 'emphasizedSuccess' | 'emphasizedRed' = 'emphasizedGray';
  @Input() chipClass2: 'emphasizedOrange' | 'emphasizedGray' | 'emphasizedBlue' | 'emphasizedSuccess' | 'emphasizedRed' = 'emphasizedGray';
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() icon: TIcons = null;
  @Input() dataCy: string = '';

  @Output() expandEmitter = new EventEmitter<boolean>();
}
