import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  inject,
  Input,
  Output
} from '@angular/core';
import { ISubNav, IUserOrganizationDto } from '@shared/interfaces';
import { Router } from '@angular/router';
import { NavbarRoutes } from '@shared/utils/navbar-routes';
import { inOutAnimation, inOutHorizontalAnimation } from '@shared/utils/animations/in-out-animation';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MobileOrganizationsListComponent } from '../mobile-organizations-list/mobile-organizations-list.component';
import { BehaviorSubject, take, takeUntil } from 'rxjs';
import { MessagingNotificationModel, OrganizationModel } from '@shared/models';
import { TColorThemes, TPlatform } from '@shared/type/index.type';
import { OfflineService } from '@services/offline/offline.service';
import { BottomSheetOptionsComponent } from '@shared/components/bottom-sheet-options/bottom-sheet-options.component';
import { IBottomSheetItem } from '@shared/interfaces/bottom-sheet';
import { LanguageService } from '@services/internal/language.service';
import { UsersService } from '@services/users.service';
import { OfflineStatusService } from '@services/offline/offline-status.service';
import { GetLabelURL } from '@shared/utils/theme-based-urls';
import { DestroySubscriptions } from '@shared/classes/destroy-subscriptions';
import { PushNotificationsService } from '@services/push-notifications.service';
import { environment } from '@environment';
import { GlobalStoreService } from '@services/internal/global-store.service';
import { PermissionService } from '@services/internal/permission.service';
import { TrackByLink } from '@shared/utils/form-elements/track-by';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MessagingComponent } from '@shared/components/global/messaging/messaging.component';
import { CreateSmallEntityConfig } from '@constants';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
  animations: [inOutAnimation, inOutHorizontalAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class MobileMenuComponent extends DestroySubscriptions implements AfterViewInit {
  @Input() showDirectoryRoutes: boolean = false;
  @Input() showMovementRoutes: boolean = false;
  @Input() showInventoryRoutes: boolean = false;
  @Input() showBurgerMenu: boolean = false;
  @Input() userName: string = '';
  @Input() nameInitials: string = '';
  @Input() messagingNotifications: MessagingNotificationModel[] = [];
  @Input() totalMessagingNotifications: number = 0;
  @Input() showAuditState: boolean = false;

  @Output() inventoryMenuTogglerEmitter = new EventEmitter<boolean>();
  @Output() movementMenuTogglerEmitter = new EventEmitter<boolean>();
  @Output() directoryMenuTogglerEmitter = new EventEmitter<boolean>();
  @Output() menuTogglerEmitter = new EventEmitter<boolean>();
  @Output() logoutEmitter = new EventEmitter<void>();
  @Output() openAIFeaturesListModalEmitter = new EventEmitter<void>();

  permissionService = inject(PermissionService);
  pushNotificationsService = inject(PushNotificationsService);
  private offlineStatusService = inject(OfflineStatusService);
  private router = inject(Router);
  private bottomSheet = inject(MatBottomSheet);
  private offlineService = inject(OfflineService);
  private ref = inject(ChangeDetectorRef);
  private dialog = inject(MatDialog);

  innerHeight$: BehaviorSubject<number> = new BehaviorSubject<number>(window.innerHeight);
  readonly currentOrganization: OrganizationModel = UsersService.getUser().organization;
  readonly organizations$: BehaviorSubject<IUserOrganizationDto[]> = UsersService.organizations$;
  readonly isOffline$: BehaviorSubject<boolean> = this.offlineStatusService.isOffline$;
  readonly colorTheme$: BehaviorSubject<TColorThemes> = UsersService.colorTheme$;
  readonly inventoryRoutes: ISubNav[] = NavbarRoutes.inventory;
  readonly movementRoutes: ISubNav[] = NavbarRoutes.movements;
  readonly directoryRoutes: ISubNav[] = NavbarRoutes.directory;
  readonly platform: TPlatform = GlobalStoreService.getPlatform();
  readonly getLabelURL: string = GetLabelURL();
  readonly isNetworkAvailable$ = this.offlineStatusService.isNetworkAvailable$;
  readonly isProd = environment.production;
  readonly trackBy = TrackByLink;
  readonly isDemoModeOnly: boolean = UsersService.getUser().organization.isDemoModeOnly;
  readonly isCustodianOnly: boolean = UsersService.getUser().isCustodianOnly;

  // iPhone can have Safari search bar on top or bottom, it changes innerHeight value
  @HostListener('window:resize', ['$event'])
  onResize(_event: Event): void {
    if (this.platform === 'web') {
      this.innerHeight$.next(window.innerHeight);
    }
  }

  ngAfterViewInit() {
    // Offline mode fix force update UI on network change to disable/enable back online button,
    // async pipe ignores update UI in some cases in offline mode
    if (this.platform !== 'web') {
      this.isNetworkAvailable$.pipe(takeUntil(this.subscriptions)).subscribe(_val => {
        setTimeout(() => this.ref.detectChanges());
      });
    }
  }

  openBottomSheet(): void {
    this.bottomSheet.open(MobileOrganizationsListComponent, {
      data: { organizations: this.organizations$.value, currentOrgId: this.currentOrganization.id },
      panelClass: 'customBottomSheet'
    });
  }

  isActive = (urls: string[]): boolean => urls.some((url: string) => this.router.url.includes(url));

  openMessagingWindow(): void {
    const dialogConfig: MatDialogConfig = { ...CreateSmallEntityConfig };

    dialogConfig.height = 'auto';
    dialogConfig.data = this.messagingNotifications;
    this.dialog.open(MessagingComponent, dialogConfig);
  }

  enableOffline(): void {
    const items: IBottomSheetItem[] = [
      { name: '1 ' + LanguageService.instant('shared.labels.hour'), id: '1' },
      { name: '2 ' + LanguageService.instant('shared.labels.hours'), id: '2' },
      { name: '3 ' + LanguageService.instant('shared.labels.hours'), id: '3' },
      { name: '8 ' + LanguageService.instant('shared.labels.hours'), id: '8' }
    ];
    this.bottomSheet
      .open(BottomSheetOptionsComponent, {
        panelClass: 'customBottomSheet',
        data: { items, title: 'offline.selectHoursTile' }
      })
      .afterDismissed()
      .pipe(take(1))
      .subscribe((hours: IBottomSheetItem) => {
        if (hours) {
          this.offlineService.enableOfflineMode(Number(hours.id));
          this.menuTogglerEmitter.emit(false);
        }
      });
  }

  disableOffline(): void {
    this.offlineService.disableOfflineMode();
  }
}
