import { ISubNav } from '@shared/interfaces';

export class NavbarRoutes {
  static readonly directory: ISubNav[] = [
    {
      link: '/directory/physicians',
      name: 'shared.tooltips.navigation.physicians',
      activeLink: ['directory/physicians'],
      permissionScope: 'config',
      permissionName: 'validRole',
      dataCy: 'physicians'
    },
    {
      link: '/directory/preference-cards',
      name: 'shared.tooltips.navigation.preferenceCards',
      activeLink: ['directory/preference-cards'],
      permissionScope: 'userLevel',
      permissionName: 'manageInventoryMovementsAllowed',
      dataCy: 'preferenceCards'
    },
    {
      link: '/directory/facilities',
      name: 'shared.tooltips.navigation.facilities',
      activeLink: ['directory/facilities'],
      permissionScope: 'config',
      permissionName: 'validRole',
      dataCy: 'facilities'
    },
    {
      link: '/directory/price-lists',
      name: 'shared.tooltips.navigation.priceLists',
      activeLink: ['directory/price-lists'],
      permissionScope: 'directory',
      permissionName: 'readPriceSheets',
      dataCy: 'price-lists'
    },
    {
      link: '/directory/procedures',
      name: 'shared.tooltips.navigation.procedures',
      activeLink: ['directory/procedures'],
      permissionScope: 'config',
      permissionName: 'validRole',
      dataCy: 'procedures'
    },
    {
      link: '/directory/manufacturers',
      name: 'shared.tooltips.navigation.manufacturers',
      activeLink: ['directory/manufacturers'],
      permissionScope: 'config',
      permissionName: 'validRole',
      dataCy: 'manufacturers'
    },
    {
      link: '/directory/regions',
      name: 'shared.tooltips.navigation.regions',
      activeLink: ['directory/regions'],
      permissionScope: 'directory',
      permissionName: 'readRegions',
      dataCy: 'regions'
    }
  ];
  static readonly inventory: ISubNav[] = [
    {
      link: '/inventory/inventories',
      name: 'shared.tooltips.navigation.allInventory',
      activeLink: ['inventory/inventories'],
      permissionScope: 'config',
      permissionName: 'validRole',
      dataCy: 'allInventory'
    },
    {
      link: '/inventory/import',
      name: 'shared.tooltips.navigation.imports',
      activeLink: ['inventory/import'],
      permissionScope: 'inventory',
      permissionName: 'canReadInventoryImport',
      dataCy: 'inventoryImport'
    },
    {
      link: 'inventory/products',
      name: 'shared.tooltips.navigation.productLines',
      activeLink: ['inventory/products'],
      permissionScope: 'config',
      permissionName: 'validRole',
      dataCy: 'productLine'
    },
    {
      link: 'inventory/catalog',
      name: 'shared.tooltips.navigation.catalogItems',
      activeLink: ['inventory/catalog'],
      permissionScope: 'config',
      permissionName: 'validRole',
      dataCy: 'catalogNumber'
    },
    {
      link: 'inventory/bill-of-materials',
      name: 'shared.tooltips.navigation.bom',
      activeLink: ['bill-of-materials'],
      permissionScope: 'inventory',
      permissionName: 'createBOM',
      dataCy: 'BOM'
    },
    {
      link: 'inventory/audits/',
      name: 'shared.tooltips.navigation.audits',
      activeLink: ['audits'],
      permissionScope: 'inventory',
      permissionName: 'readAudits',
      dataCy: 'audits'
    }
  ];
  static readonly movements: ISubNav[] = [
    {
      link: '/movement/requests',
      name: 'shared.tooltips.navigation.requests',
      activeLink: ['movement/requests'],
      permissionScope: 'config',
      permissionName: 'validRole',
      dataCy: 'inventoryRequests'
    },
    {
      link: '/movement/transfers',
      name: 'shared.tooltips.navigation.transfers',
      activeLink: ['movement/transfers'],
      permissionScope: 'config',
      permissionName: 'validRole',
      dataCy: 'transfers'
    },
    {
      link: '/movement/locations',
      name: 'shared.tooltips.navigation.locations',
      activeLink: ['movement/locations'],
      permissionScope: 'userLevel',
      permissionName: 'manageInventoryMovementsAllowed',
      dataCy: 'locations'
    }
  ];
  static readonly mobilePrimaryRoutes: string[] = [
    '/dashboard',
    '/events/list',
    '/inventory/inventories/list',
    '/inventory/import/list',
    '/inventory/products/list',
    '/inventory/catalog/list',
    '/inventory/bill-of-materials/list',
    '/inventory/audits/list',
    '/movement/requests/list',
    '/movement/transfers/list',
    '/movement/locations',
    '/directory/price-lists',
    '/directory/physicians/list',
    '/directory/preference-cards/list',
    '/directory/facilities/list',
    '/directory/procedures/list',
    '/directory/manufacturers/list',
    '/directory/regions/list',
    '/notifications',
    '/users',
    '/settings/user-profile',
    '/settings/organization',
    '/settings/integrations',
    '/statistics',
    '/app-info',
    '/integrations'
  ];
}
