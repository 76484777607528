<app-default-modal
  [size]="'small'"
  [title]="'events.detail.purchaseOrderNumberLabel' | language"
  [disableSubmitButton]="!poNumbersFulFilled || !poDate"
  [buttonTitle]="'shared.labels.submit' | language"
  [scrollableContent]="true"
  (clickEmitter)="savePONumber()"
>
  <div data-layout="column" data-layout-gap="16px" data-flex="100">
    <span class="poNumberHint"> {{ 'events.detail.fillPOCaption' | language }} </span>

    <app-datepicker-field-kit
      [label]="'events.detail.poDateLabel' | language"
      [initialValue]="poDate"
      [inputDataCy]="'eventPODateInput'"
      [icon]="'calendar'"
      [required]="true"
      (dateEmitter)="poDate = $event.value"
    ></app-datepicker-field-kit>

    <div data-layout="column" data-layout-gap="8px">
      <span class="poNumberHint">{{ 'events.detail.addPOForAllLabel' | language }} </span>

      <app-input-kit
        [dataCy]="'eventPONumberInput'"
        [label]="('events.detail.poLabel' | language) + ' #'"
        [initialValue]="commonPONumber"
        (valueChangedEmitter)="commonPONumber = $event; setPoNumbersForAllManufactures(); checkEmpty()"
      ></app-input-kit>
    </div>

    <div
      *ngFor="let manufacturePO of data.poNumbers; trackBy: trackBy"
      data-layout-xs="column"
      data-layout="row"
      data-layout-gap="16px"
      data-layout-align-xs="start stretch"
      data-layout-align="space-between center"
    >
      <span data-flex-xs="100" data-flex="33" class="poNumberManufacturerName">{{ manufacturePO.manufacturer.name }}</span>
      <app-input-kit
        data-flex="66"
        data-flex-xs="100"
        [dataCy]="'eventPONumberInput'"
        [label]="('events.detail.poLabel' | language) + ' #'"
        [initialValue]="manufacturePO.poNumber"
        (valueChangedEmitter)="manufacturePO.poNumber = $event; checkEmpty()"
      ></app-input-kit>
    </div>
  </div>
</app-default-modal>
