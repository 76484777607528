import { ChangeDetectionStrategy, Component } from '@angular/core';
import { GetAiIconURL } from '@shared/utils/theme-based-urls';
import { GlobalStoreService } from '@services/internal/global-store.service';
import { UsersService } from '@services/users.service';

@Component({
  selector: 'app-ai-widget-button',
  templateUrl: './ai-widget-button.component.html',
  styleUrl: './ai-widget-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AiWidgetButtonComponent {
  readonly aiIconUrl: string = GetAiIconURL();
  private shadowRootHandler: EventListener;
  private aiSearchWidgetRef: HTMLElement;
  private readonly styledTags = new Set([
    'gen-search-widget',
    'ucs-results',
    'ucs-conversation',
    'ucs-summary',
    'ucs-text-streamer',
    'ucs-search-bar',
    'ucs-markdown',
    'ucs-search-results',
    'ucs-search-skeleton-loader',
    'ucs-search-toolbar'
  ]);

  static renameSearchWidgetPlaceholder(): void {
    const changePlaceholder = () => {
      const input = document
        .querySelector('gen-search-widget')
        ?.shadowRoot?.querySelector('ucs-results')
        ?.querySelector('ucs-search-bar')
        ?.shadowRoot?.querySelector('input');
      if (input) {
        input.placeholder = 'Search our knowledgebase.';
      }
    };

    setTimeout(() => changePlaceholder(), 500);
    setTimeout(() => changePlaceholder(), 1000);
    setTimeout(() => changePlaceholder(), 2000);
  }

  toggleAISideBar(): void {
    if (!this.aiSearchWidgetRef) {
      this.aiSearchWidgetRef = document.querySelector('gen-search-widget');
    }
    if (!this.aiSearchWidgetRef) {
      return;
    }
    GlobalStoreService.isAISidebarOpen.set(!GlobalStoreService.isAISidebarOpen());
    let minifiedStyles = this.minifyCss(this.mobileStyles);
    if (UsersService.getUser().colorTheme !== 'LIGHT') {
      minifiedStyles += this.minifyCss(this.darkModeStyles());
    }

    this.shadowRootHandler = this.debounce(() => {
      if (this.aiSearchWidgetRef) {
        this.injectStylesRecursively(this.aiSearchWidgetRef, minifiedStyles);
      }
    }, 300);

    if (!GlobalStoreService.isAISidebarOpen()) {
      this.aiSearchWidgetRef.removeEventListener('shadowrootcreated', this.shadowRootHandler);
    } else {
      this.aiSearchWidgetRef.addEventListener('shadowrootcreated', this.shadowRootHandler);
      this.injectStylesRecursively(this.aiSearchWidgetRef, minifiedStyles);
    }

    const messagingContainer = document.getElementById('messagingContainer');
    if (GlobalStoreService.isAISidebarOpen()) {
      messagingContainer.style.right = '384px';
    } else {
      messagingContainer.style.right = '64px';
    }
  }

  private injectMobileStyles(shadowRoot: ShadowRoot, mobileStyles: string) {
    if (!shadowRoot.querySelector('style[data-mobile-styles]')) {
      const styleEl = document.createElement('style');
      styleEl.setAttribute('data-mobile-styles', 'true');
      styleEl.textContent = mobileStyles;
      shadowRoot.appendChild(styleEl);
    }
  }

  private injectStylesRecursively(root: any, styles: string) {
    if (!root) {
      return;
    }

    const elements = this.findAllCustomElements(root);

    for (const el of elements) {
      const shadow = el.shadowRoot;
      if (shadow && !shadow.querySelector('style[data-mobile-styles]')) {
        this.injectMobileStyles(shadow, styles);
        if (el.tagName.toLowerCase() === 'ucs-search-bar') {
          AiWidgetButtonComponent.renameSearchWidgetPlaceholder();
        }
      }
    }
  }

  private findAllCustomElements(root: Element | ShadowRoot): HTMLElement[] {
    const matched: HTMLElement[] = [];

    const walk = (node: Element | ShadowRoot) => {
      if (node instanceof HTMLElement && this.styledTags.has(node.tagName.toLowerCase())) {
        matched.push(node);
      }

      const children = ((node as HTMLElement).shadowRoot || node).querySelectorAll('*');
      for (const el of Array.from(children)) {
        if (el.shadowRoot) {
          walk(el.shadowRoot);
        }
        if (el instanceof HTMLElement && this.styledTags.has(el.tagName.toLowerCase())) {
          matched.push(el);
        }
      }
    };

    walk(root);

    return matched;
  }

  private debounce(fn: Function, delay: number): EventListener {
    let timeoutId: number;
    return (...args: any[]) => {
      clearTimeout(timeoutId);
      timeoutId = window.setTimeout(() => fn.apply(this, args), delay);
    };
  }

  private readonly mobileStyles = `
    .search-toolbar {
      gap: 12px !important;
      padding-left: 24px !important;
      padding-right: 24px !important;
    }
    ucs-search-bar {
      padding-left: 24px !important;
      padding-right: 24px !important;
    }
    div[role=dialog] {
      width: 320px !important;
      left: auto !important;
    }
    .main {
      padding-left: 4px !important;
      padding-right: 4px !important;
    }
    .scroller {
      padding: 0 !important;
      width: unset !important;
    }
    .search-results-container {
      padding-left: 24px !important;
      padding-right: 24px !important;
    }
    ucs-search-bar {
      padding-left: 24px !important;
      padding-right: 24px !important;
    }
    .backdrop {
      display: none !important;
    }
    .summary-container {
      padding: 0 16px !important;
    }
  `;

  private minifyCss(css: string): string {
    return css
      .replaceAll(/\s+/g, ' ')
      .replaceAll(/\s*{\s*/g, '{')
      .replaceAll(/\s*}\s*/g, '}')
      .replaceAll(/\s*:\s*/g, ':')
      .replaceAll(/;\s*/g, ';')
      .trim();
  }

  private readonly darkModeStyles = () => {
    const base = getComputedStyle(document.body).getPropertyValue('--base').trim();
    const surface = getComputedStyle(document.body).getPropertyValue('--surface').trim();
    const textCaption = getComputedStyle(document.body).getPropertyValue('--text-caption').trim();

    return `
      .search-toolbar {
        background-color: ${base} !important;
      }
      ucs-search-bar {
        background-color: ${base} !important;
      }
      .search-results-container {
        background-color: ${base} !important;
      }
      .item .rows .header a {
        color: inherit !important;
      }
      .main form .main-label {
        background-color: ${base} !important;
      }
      .content {
        background-color: ${surface} !important;
      }
      .main {
        background-color: inherit !important;
      }
      .markdown-container {
         color: ${textCaption} !important;
      }
      form.showing-follow-up-input {
        background-color: inherit !important;
      }
      .results-header {
        color: inherit !important;
      }
      .follow-up-label {
        background-color: inherit !important;
      }
      .question-block + ucs-summary {
          background: inherit !important;
      }
      .question-block {
        background-color: ${base} !important;
      }
      .question-block .question-wrapper .question-bubble {
        background-color: ${surface} !important;
      }
    `;
  };
}
