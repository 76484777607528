import { LocationPlaceModel } from '@shared/models/features/movement/location/location-place.model';

export const location = (place: any): LocationPlaceModel => {
  /**
   * Changed location data to format which expect backend
   * Returns LocationModel or {}
   */
  if (!place) {
    return new LocationPlaceModel();
  }

  const locationParams: any = {};
  const addressComp = place.address_components || [];

  locationParams.placeId = place.place_id;
  locationParams.locationName = place.name;
  locationParams.street = place.formatted_address;

  if (place.geometry) {
    if (typeof place.geometry.location.lat === 'function') {
      locationParams.latitude = place.geometry.location.lat().toString();
    } else {
      locationParams.latitude = place.geometry.location.toString();
    }
    if (typeof place.geometry.location.lng === 'function') {
      locationParams.longitude = place.geometry.location.lng().toString();
    } else {
      locationParams.longitude = place.geometry.location.toString();
    }
  }

  addressComp.forEach((c: any) => {
    c.types.forEach((type: string): boolean | void => {
      if (type === 'locality') {
        locationParams.city = c.long_name;
        return true;
      }
      if (type === 'administrative_area_level_1') {
        locationParams.locationState = c.long_name;
        return true;
      }
      if (type === 'country') {
        locationParams.country = c.long_name;
        return true;
      }
      if (type === 'postal_code') {
        locationParams.postalCode = c.long_name;
      }
    });
  });
  return locationParams;
};
