import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ContactModel, FacilityModel } from '@shared/models';
import { CatalogService } from '@services/catalog.service';
import { FacilityService } from '@services/facility.service';
import { AlertsService } from '@services/internal/alerts.service';
import { PermissionService } from '@services/internal/permission.service';
import { CreateFacilitiesComponent } from '../create-facilities/create-facilities.component';

@Component({
  selector: 'app-modal-edit-contacts',
  templateUrl: './modal-edit-contacts.component.html',
  styleUrls: ['./modal-edit-contacts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class ModalEditContactsComponent extends CreateFacilitiesComponent implements OnInit {
  canEdit: boolean = false;
  updateContacts: boolean = false;
  primaryContact: ContactModel;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { facility: FacilityModel; newEmail?: string },
    public permissionService: PermissionService,
    public ref: ChangeDetectorRef,
    public facilityService: FacilityService,
    public router: Router,
    public alertsService: AlertsService,
    public catalogService: CatalogService,
    private dialog: MatDialogRef<ModalEditContactsComponent>,
    @Optional() public dialogRef: MatDialogRef<CreateFacilitiesComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public redirect: boolean
  ) {
    super(facilityService, router, alertsService, catalogService, ref, permissionService, dialogRef, redirect);
  }

  ngOnInit(): void {
    this.data.facility?.facilityContacts?.forEach(c => {
      if (c.type === 'PRIMARY') {
        this.primaryContact = c;
      }

      if (c.type === 'ADDITIONAL_PRIMARY') {
        this.additionalContactsData.push(c);
      }

      if (c.type === 'BILLING') {
        setTimeout(() => this.setBillingFormValues(c));
      }
    });

    if (this.data.newEmail) {
      const contact: ContactModel = new ContactModel();

      contact.email = this.data.newEmail;
      this.addFacilityContact(contact);
    }
  }

  close(facility?: FacilityModel): void {
    this.dialog.close({ updateContacts: this.updateContacts, facility });
  }

  setBillingFormValues(contact: ContactModel): void {
    const facility: FacilityModel = this.data.facility;

    this.formBillingContacts.setValue({
      id: contact?.id ?? null,
      name: contact?.name ?? null,
      email: contact?.email ?? null,
      additionalEmail: contact?.additionalEmail ?? null,
      phone: contact?.phone ?? null,
      phoneExtension: contact?.phoneExtension ?? null,
      paymentTerm: facility.paymentTerm ?? null,
      paymentStreetAddress: facility.paymentStreetAddress,
      paymentZipCode: facility.paymentZipCode,
      paymentAddressState: facility.paymentAddressState,
      paymentCity: facility.paymentCity,
      isCompletedContract: facility.isCompletedContract,
      contractRenewalDate: facility.contractRenewalDate,
      useAlternateCatalogs: facility.useAlternateCatalogs,
      isCreditHold: facility.isCreditHold,
      isSalesOrderDefault: contact.isSalesOrderDefault ?? false,
      isInvoiceDefault: contact.isInvoiceDefault ?? false
    });
  }

  /**
   * Needs for correct request params for saving, because we can get a 500 error without required params from the formName
   */
  setFormNameValues(): void {
    const facility: FacilityModel = this.data.facility;

    this.formName.setValue({
      name: facility.name,
      streetAddress: facility.streetAddress,
      zipCode: facility.zipCode,
      facilityType: facility.facilityType,
      city: facility.city,
      addressState: facility.addressState,
      geographicalType: facility.geographicalType,
      externalId: facility.externalId
    });
  }

  save(): void {
    this.setFormNameValues();
    const queryParams = CreateFacilitiesComponent.buildRequestParams(this);
    this.updateContacts = true;
    this.close(queryParams);
  }

  checkContactsValidation(): void {
    setTimeout(() => {
      const additionalContactsValid: boolean = this.additionalContacts.reduce((accumulator: boolean, currentValue: UntypedFormGroup) => {
        if (!currentValue.valid) {
          return false;
        }
        return accumulator;
      }, true);
      this.formsValid = this.formPrimaryContacts?.valid && this.formBillingContacts?.valid && additionalContactsValid;

      let contactValid: boolean = false;

      if (this.formsValid) {
        if (this.formPrimaryContacts.value.email || this.formPrimaryContacts.value.phone) {
          contactValid = true;
        }

        if (
          this.formBillingContacts.value.email ||
          this.formBillingContacts.value.additionalEmail ||
          this.formBillingContacts.value.phone
        ) {
          contactValid = true;
        }

        const additionalContactValid = this.additionalContacts.some(form => form.value.email || form.value.phone);

        if (additionalContactValid) {
          contactValid = true;
        }
      }

      this.formsValid = contactValid;

      this.ref.markForCheck();
    }, 50);
  }
}
