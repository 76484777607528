import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, finalize, map, Observable, of, BehaviorSubject, filter, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GoogleApiService {
  private httpClient = inject(HttpClient);
  private isGoogleMapsLoading$ = new BehaviorSubject<boolean>(false);

  loadGoogleMapsAPI(): Observable<boolean> {
    if (window.google?.maps) {
      return of(true);
    }

    if (this.isGoogleMapsLoading$.value) {
      return this.waitForLoadingToComplete();
    }

    this.isGoogleMapsLoading$.next(true);
    // jsonp requests throw an error if there are header in request
    return this.httpClient
      .jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyDs5BtQrxaysGyEiKsSU8F5HzKJySMToBw&libraries=places', 'callback')
      .pipe(
        map(() => true),
        finalize(() => this.isGoogleMapsLoading$.next(false)),
        catchError(() => {
          this.isGoogleMapsLoading$.next(false);
          return of(false);
        })
      );
  }

  private waitForLoadingToComplete(): Observable<boolean> {
    return this.isGoogleMapsLoading$.pipe(
      filter(loading => !loading),
      take(1),
      map(() => true)
    );
  }
}
